<template>
  <div>
    <form
      @submit.prevent="handleSubmit"
      v-shortkey="shortkeys"
      @shortkey="handleShortkey"
      class="container app-settings"
    >
      <fieldset>
        <legend>
          Default Printer Settings
          <icon-button
            v-if="permissions.PrinterCreateEdit"
            icon="print"
            @click="refreshLabPrinters"
            class="btn-primary m-1"
          >
            Refresh Printers
          </icon-button>
        </legend>
        <div class="row">
          <printer-select-with-status
            label="Label Printer"
            name="labPrinters"
            :items="labPrinters"
            class="col-6"
            v-model="defaultLabelPrinter"
          />
          <div class="col-6"></div>
          <printer-select-with-status
            label="Spec. Label Printer"
            name="defaultSpecLabelPrinter"
            :items="labPrinters"
            class="col-6"
            v-model="defaultSpecLabelPrinter"
          />
          <printer-select-with-status
            label="Req. Label Printer"
            :items="labPrinters"
            name="reqLabelPrinter"
            class="col-6"
            v-model="defaultReqLabelPrinter"
          />
          <printer-select-with-status
            label="Cassette Printer"
            name="cassettePrinter"
            :items="labPrinters"
            class="col-6"
            v-model="defaultCassettePrinter"
          />
          <printer-select-with-status
            label="Tag/Note Printer"
            name="tagNotePrinter"
            :items="labPrinters"
            class="col-6"
            v-model="tagNotePrinter"
          />
          <printer-select-with-status
            label="Slide Printer"
            name="slidePrinter"
            :items="labPrinters"
            class="col-6"
            v-model="defaultSlidePrinter"
          />
          <printer-select-with-status
            label="Glass Slide Printer"
            :items="labPrinters"
            name="glassSlidePrinter"
            class="col-6"
            v-model="defaultGlassSlidePrinter"
          />
          <printer-select-with-status
            label="Orders Report Printer"
            name="ordersReportPrinter"
            :items="labPrinters"
            class="col-6"
            v-model="defaultOrdersReportPrinter"
          />
          <printer-select-with-status
            label="Batch Printer"
            name="defaultBatchPrinter"
            :items="batchPrinterOptions"
            class="col-6"
            valueExpr="printerId"
            v-model="defaultBatchPrinter"
          />
          <printer-select-with-status
            label="History Printer"
            name="defaultHistoryPrinter"
            :items="labPrinters"
            class="col-6"
            valueExpr="printerId"
            v-model="defaultHistoryPrinter"
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>Twain</legend>
        <div class="row">
          <SelectInput
            label="Enable Twain"
            :items="booleanOptions"
            name="enableTwain"
            class="col"
            v-model="userSettings.enableTWAINDriver"
          />
        </div>
      </fieldset>
      <button class="btn btn-primary mt-3 mb-1" @click.prevent="toggleIsGridsResetOpen">
        <u>G</u>rid Maintenance
      </button>
      <fieldset>
        <legend>User Settings</legend>
        <UserSettingsFields v-model="userSettings" @updateSettings="updateUserSettings" />
      </fieldset>

      <div class="d-flex justify-content-end p-1">
        <button class="btn btn-primary ml-auto mt-3" type="submit">Save</button>
      </div>
    </form>
    <modal :status="isResetGridOpen" @close="toggleIsGridsResetOpen">
      <ResetGrids @close="toggleIsGridsResetOpen" />
    </modal>
    <modal :status="isCustomResultStatusOpen" @close="toggleCustomResultStatusPopup">
      <CustomResultStatusPopup
        :statusProp="customResultStatuses"
        @cancel="toggleCustomResultStatusPopup"
        @submit="handleCustomStatusSubmit"
      />
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { PrintersApi } from "../services";
import SelectInput from "@/components/common/SelectInput.vue";
import localforage from "localforage";
import { booleanLookup, getAltKeys } from "@/modules/helpers";
import TWAINDriver from "@/modules/TWAINDriver";
import IconButton from "./common/IconButton.vue";
import PrinterSelectWithStatus from "./PrinterSelectWithStatus.vue";
import { sortBy } from "lodash";
import Modal from "@/components/common/Modal.vue";
import ResetGrids from "@/components/ResetGrids.vue";
import CustomResultStatusPopup from "@/components/CustomResultStatusPopup.vue";
import { CaseStatusEnum } from "@/modules/enums";
import { defaultUserSettings } from "@/modules/defaultUserSettings";
import UserSettingsFields from "@/components/UserSettingsFields.vue";
import eventBus, { UPDATE_CUSTOM_RESULTS_STATUS } from "@/modules/eventBus";

export default {
  components: {
    SelectInput,
    IconButton,
    PrinterSelectWithStatus,
    Modal,
    ResetGrids,
    CustomResultStatusPopup,
    UserSettingsFields
  },
  name: "App-Settings",
  data() {
    return {
      userSettings: { ...defaultUserSettings },
      supportsCache: localforage.supports(localforage.INDEXEDDB),
      labPrinters: [],
      booleanOptions: booleanLookup.dataSource,
      isLoadingLabMacros: false,
      defaultLabelPrinter: null,
      defaultSpecLabelPrinter: null,
      defaultReqLabelPrinter: null,
      defaultSlidePrinter: null,
      defaultGlassSlidePrinter: null,
      defaultOrdersReportPrinter: null,
      defaultPrintMode: null,
      defaultCassettePrinter: null,
      defaultBatchPrinter: "",
      defaultHistoryPrinter: null,
      tagNotePrinter: null,
      isResetGridOpen: false,
      customResultStatuses: [],
      isCustomResultStatusOpen: false,
      shortkeys: getAltKeys("gs")
    };
  },

  created() {
    PrintersApi.getLabPrinters().then(res => {
      this.labPrinters = sortBy(res, "displayName") || [];
      this.checkAllPrinters();
    });
    this.loadAppSettings();
    if (typeof this.userSettings.nextCasePopup === "boolean") {
      // Convert old next case popup option from boolean to number.
      this.userSettings.nextCasePopup = this.userSettings.nextCasePopup ? 1 : 0;
    }
  },
  computed: {
    ...mapState({
      applicationSettings: state => state.applicationSettings,
      labSettings: state => state.labSettings,
      reportViewer: state => state.report,
      LabPrintNodeAPIKey: state => state.labSettings.LabPrintNodeAPIKey,
      accessionMode: state => state.applicationSettings.accessionMode
    }),
    ...mapGetters(["permissions"]),
    selectedCustomResultStatuses() {
      if (!this.customResultStatuses?.length) {
        return "Spec, Gross, Orders";
      }
      let selectedStatusNames = [];
      const statuses = Object.keys(CaseStatusEnum).map(e => {
        return {
          id: CaseStatusEnum[e],
          displayName: e
        };
      });
      for (const statusId of [...this.customResultStatuses].sort(function (a, b) {
        return a - b;
      })) {
        const statusData = statuses.find(e => e.id === statusId);
        selectedStatusNames.push(statusData.displayName);
      }
      return selectedStatusNames.join(", ");
    },
    batchPrinterOptions() {
      return [{ displayName: "Contact Printers", printerId: "" }, ...this.labPrinters];
    }
  },
  methods: {
    async loadAppSettings() {
      const settingsNames = Object.keys(this.applicationSettings);
      for (const setting of settingsNames) {
        if (setting === "defaultPrintMode") {
          if (!this.applicationSettings.defaultPrintMode) {
            this.userSettings.defaultPrintMode = this.labSettings.DefaultSlidePrintMode;
          } else {
            this.userSettings.defaultPrintMode = this.applicationSettings.defaultPrintMode;
          }
        } else if (Object.keys(defaultUserSettings).includes(setting)) {
          this.userSettings[setting] = this.applicationSettings[setting];
        } else {
          this[setting] = this.applicationSettings[setting];
        }
      }
      if (this.applicationSettings.enableTWAINDriver) {
        const twainData = TWAINDriver.load();
        this.twainDevices = twainData?.devices || [];
        this.twainStatus = twainData?.status || 0;
      }
    },
    async refreshLabPrinters() {
      try {
        this.$el.style.cursor = "wait";
        await PrintersApi.refreshLabPrinters(this.LabPrintNodeAPIKey);
        this.labPrinters = await PrintersApi.getLabPrinters();
        this.checkAllPrinters();
        window.notify("Refreshed lab printers!");
      } catch (e) {
        window.notify("Error occured refreshing printers.", "error");
      } finally {
        this.$el.style.cursor = "";
      }
    },
    async checkAllPrinters() {
      const invalidPrinters = [
        "defaultLabelPrinter",
        "defaultSpecLabelPrinter",
        "defaultReqLabelPrinter",
        "defaultCassettePrinter",
        "tagNotePrinter",
        "defaultSlidePrinter",
        "defaultGlassSlidePrinter",
        "defaultOrdersReportPrinter"
      ].filter(printer => {
        const printerId = this[printer];
        const printerIdx = this.labPrinters.findIndex(e => e.id === printerId);
        if (printerId && printerIdx === -1) {
          return true;
        }
        return false;
      });
      const printerLabels = {
        defaultLabelPrinter: "Label Printer",
        defaultSpecLabelPrinter: "Spec.  Printer",
        defaultReqLabelPrinter: "Req.  Printer",
        defaultCassettePrinter: "Cassette Printer",
        tagNotePrinter: "Tag/Note Printer",
        defaultSlidePrinter: "Slide Printer",
        defaultGlassSlidePrinter: "Glass Slide Printer",
        defaultOrdersReportPrinter: "Orders Report Printer"
      };
      if (invalidPrinters.length) {
        const confirm = await window.confirm(
          `<div><p>The following printers have value that could no longer be found.</p> <ol>${invalidPrinters
            .map(e => `<li>${printerLabels[e]}</li>`)
            .join("")}</ol><b>Would you like to clear them?</b></div>`
        );
        if (confirm) {
          invalidPrinters.forEach(e => (this[e] = null));
          this.updatePrinterSettings();
        }
      }
    },
    updatePrinterSettings() {
      this.$store.commit("applicationSettings/setDefaultLabelPrinter", this.defaultLabelPrinter);
      this.$store.commit(
        "applicationSettings/setDefaultSpecLabelPrinter",
        this.defaultSpecLabelPrinter
      );
      this.$store.commit(
        "applicationSettings/setDefaultReqLabelPrinter",
        this.defaultReqLabelPrinter
      );
      this.$store.commit("applicationSettings/setDefaultSlidePrinter", this.defaultSlidePrinter);
      this.$store.commit(
        "applicationSettings/setDefaultGlassSlidePrinter",
        this.defaultGlassSlidePrinter
      );
      this.$store.commit(
        "applicationSettings/setDefaultOrdersReportPrinter",
        this.defaultOrdersReportPrinter
      );
      this.$store.commit("applicationSettings/setTagNotePrinter", this.tagNotePrinter);
      this.$store.commit(
        "applicationSettings/setDefaultCassettePrinter",
        this.defaultCassettePrinter
      );
      this.$store.commit("applicationSettings/setDefaultBatchPrinter", this.defaultBatchPrinter);
      this.$store.commit(
        "applicationSettings/setDefaultHistoryPrinter",
        this.defaultHistoryPrinter
      );
    },
    async handleSubmit() {
      if (this.userInStaging) {
        window.alert(
          'Your user has been set to use the Staging environment. Until you turn this setting off, you must use https://staging.intellipathpro.com to use the application. To access this setting to turn it off, access the app settings popup and disable "Use Staging Environment".'
        );
      }
      this.updatePrinterSettings();

      const originalPathReportLocation = this.applicationSettings.pathReportLocation;
      const originalCustomResultsStatusesJson = JSON.stringify(
        this.applicationSettings.customResultStatuses
      );
      // User Settings
      this.$store.dispatch("applicationSettings/setUserSettings", this.userSettings);

      if (
        this.reportViewer.isOpen &&
        this.applicationSettings.pathReportLocation !== originalPathReportLocation
      ) {
        if ([0, 1].includes(this.applicationSettings.pathReportLocation)) {
          this.$store.commit("report/toggleReportViewer", false);
          setTimeout(() => {
            this.$store.commit("report/toggleReportViewer", true);
          }, 0);
        }
        if (this.applicationSettings.pathReportLocation === 2) {
          this.$store.commit("report/toggleReportViewer", false);
          window.notify("Path report preview has been closed.", "warning");
        }
      }
      if (
        this.accessionMode === "result" &&
        originalCustomResultsStatusesJson !== JSON.stringify(this.userSettings.customResultStatuses)
      ) {
        eventBus.$emit(UPDATE_CUSTOM_RESULTS_STATUS);
      }
      this.$emit("close");
    },
    toggleIsGridsResetOpen() {
      this.isResetGridOpen = !this.isResetGridOpen;
    },
    toggleCustomResultStatusPopup() {
      this.isCustomResultStatusOpen = !this.isCustomResultStatusOpen;
    },
    handleCustomStatusSubmit(data) {
      this.customResultStatuses = data;
      this.toggleCustomResultStatusPopup();
    },
    updateUserSettings(value) {
      this.userSettings = value;
    },
    handleShortkey({ srcKey }) {
      switch (srcKey) {
        case "g":
          this.toggleIsGridsResetOpen();
          break;
        case "s":
          this.handleSubmit();
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .app-settings {
    width: 60vw;
    .row {
      display: flex;
    }
  }
}
.row {
  display: block;
}
</style>
