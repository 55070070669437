export const defaultUserSettings = {
  expandDemo: true,
  defaultPrintMode: 0,
  imagePrintOnReport: false,
  defaultImageType: 0,
  pathReportLocation: 0,
  macroSearchMode: false,
  macroStartsWith: false,
  automaticMacroPopup: null,
  enableTWAINDriver: false,
  closeAfterTwain: false,
  macroAssist: true,
  autoOpenCase: false,
  segregateResultsMacros: null,
  enableSpellchecker: true,
  defaultDashboardMode: 0,
  nextCaseType: 0,
  nextCasePopup: 0,
  autoFillNextCase: 0,
  nextCaseUseDashboardMode: 0,
  autoOpenEditors: false,
  autoOpenSlideImages: false,
  confirmRemoveHold: true,
  taskGridViewAllUsers: false,
  caseOrdersFields: 0,
  disableAutoAddQuickLinks: false,
  confirmRemoveOrder: true,
  showOrdersGridAfterAdd: true,
  quickLinksNoBlocks: true,
  confirmClearResultsMacro: true,
  //   warnIfNoDistribution: true,
  macroWindowGross: false,
  preserveMacroCaseNotes: false,
  userInStaging: false,
  resultsMacroRetainScroll: false,
  useDragonEditors: false,
  macroDefaultInput: 0,
  customResultStatuses: [],
  defaultBatchPrintMode: null,
  primaryMacroWindowText: 0,
  autoOpenReqSlip: null,
  displayLastMacro: false,
  autoPathReportInfo: false,
  defaultPathReportZoom: 80,
  autoOpenSpecimenResults: false,
  autoOpenFirstEditorResults: false,
  nextCaseDashboardFilter: 0
};
