import {
  createLogComment,
  createLogItem,
  getEditTypes,
  sanitizeHTML,
  shortenAccessionNumber
} from "../modules/helpers";
import { mergeSpecimensWithMacros } from "../modules/mergeSpecimensWithMacros";
import { removeDuplicates } from "../modules/specimen";
import moment from "moment";
import {
  AuditLogApi,
  CasesApi,
  DropdownApi,
  IcdRulesApi,
  ImagesApi,
  InsuranceApi,
  PathReportMessagesApi,
  PrefixApi,
  ProceduresApi,
  QuickLinksApi,
  SpecimensApi,
  TransactionsApi
} from "../services/index";
import { orderBy, uniqBy, unset, cloneDeep } from "lodash";

import { AuditLogItems, CaseStatusEnum, MacroTypeEnum, SpecimenNumbersEnum } from "@/modules/enums";
import { handleMissingCptPairs } from "@/modules/handleMissingCptPairs";
import eventBus, {
  ICD_ENGINE_FINISH,
  ICD_ENGINE_START,
  UPDATE_CURRENT_SPECIMEN_PROSTATE
} from "@/modules/eventBus";
import { handleErrors } from "@/modules/handleErrors";

export const accessionStore = {
  namespaced: true,
  state: () => ({
    caseDetails: {},
    headerLoading: false,
    caseHeader: {},
    specimens: [],
    loading: false,
    error: null,
    reReportPopup: false,
    editType: null,
    reasonForChange: null,
    caseOrders: [],
    caseTags: [],
    caseHolds: [],
    caseNotes: [],
    transactions: [],
    caseDistributions: [],
    slideImages: [],
    caseImages: [],
    viewedPopups: [],
    insurances: [],
    casePrefix: {},
    history: [],
    currentSpecimen: {},
    caseCounts: {
      distributionCount: 0,
      historyCount: 0,
      insuranceCount: 0,
      resultsCount: 0,
      specimenCount: 0,
      txCount: 0,
      legacyHistoryCount: 0
    },
    editTypes: {
      1: "Amended",
      2: "Addendum",
      3: "Corrected",
      4: "NonDiagnostic",
      5: "Other"
    },
    specimenToLoad: null,
    shouldPrintOrders: false,
    lastResultsMacro: "",
    nextCaseToLoad: null
  }),
  getters: {
    isSigned(state) {
      if (
        [
          CaseStatusEnum.SignedOnHold,
          CaseStatusEnum.ReportedPrelim,
          CaseStatusEnum.Signed,
          CaseStatusEnum.Reported,
          CaseStatusEnum.SignedAgain,
          CaseStatusEnum.ReReleased
        ].includes(state.caseDetails.status)
      ) {
        return true;
      }
      return false;
    },
    primaryPathologist(state) {
      if (state.currentSpecimen?.pathologists) {
        const primary = state.currentSpecimen?.pathologists?.find(
          pathologist => pathologist.isPrimary
        );
        if (primary) {
          return { ...primary, guid: primary.userId };
        }
      }
      const primary = state.caseDetails?.pathologists?.find(pathologist => pathologist.isPrimary);

      return primary || null;
    },
    primaryProvider(state) {
      const primary = state.caseDetails?.contacts?.find(contact => contact.isPrimary);

      return primary || null;
    },
    isReported(state) {
      return [
        CaseStatusEnum.ReportedPrelim,
        CaseStatusEnum.Reported,
        CaseStatusEnum.ReReleased
      ].includes(state.caseDetails.status);
    },
    caseStatus(state) {
      return state.caseHeader.status;
    },
    shortenedCaseNumber(state) {
      if (state.caseDetails?.caseNumber) {
        return shortenAccessionNumber(state.caseDetails.caseNumber);
      }
      return null;
    },
    isCaseEditable(state) {
      if (
        [
          CaseStatusEnum.SignedOnHold,
          CaseStatusEnum.ReportedPrelim,
          CaseStatusEnum.Signed,
          CaseStatusEnum.Reported,
          CaseStatusEnum.SignedAgain,
          CaseStatusEnum.ReReleased
        ].includes(state.caseHeader.status)
      ) {
        if ([1, 2, 3, 4, 5, 6].includes(state.editType)) {
          return true;
        }
        return false;
      }
      return true;
    },
    editTypeName(state) {
      return getEditTypes().find(type => type.id === state.editType)?.displayName;
    },
    specimenNumbering(state, getters, rootState) {
      const contactSpecimenNumbering =
        getters.primaryProvider?.contact?.properties?.specimenNumbering;
      if (
        [SpecimenNumbersEnum.Numbers, SpecimenNumbersEnum.Letters].includes(
          contactSpecimenNumbering
        )
      ) {
        return contactSpecimenNumbering;
      }
      return rootState.labSettings.SpecimenNumberingTypes;
    }
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setCaseDetails(state, payload) {
      state.caseDetails = payload;
    },
    setReReportPopup(state, payload) {
      state.reReportPopup = payload;
    },
    setEditType(state, payload) {
      state.editType = payload;
    },
    setCaseImages(state, payload) {
      state.caseImages = payload;
    },
    setViewedPopups(state, payload) {
      state.viewedPopups = payload;
    },
    setReasonForChange(state, payload) {
      state.reasonForChange = payload;
    },
    setSlideImages(state, payload) {
      state.slideImages = payload;
    },
    setCaseSpecimens(state, payload) {
      state.specimens = orderBy(
        payload,
        [
          ({ specimenOrder }) =>
            isNaN(specimenOrder) ? specimenOrder : parseInt(specimenOrder, 10),
          "specimenOrder"
        ],
        "asc"
      );
    },
    setCaseHeader(state, payload) {
      state.caseHeader = payload;
    },
    setCaseOrders(state, payload) {
      state.caseOrders = payload;
    },
    setCaseTags(state, payload) {
      state.caseTags = payload;
    },
    setCaseHolds(state, payload) {
      state.caseHolds = payload;
    },
    setCaseNotes(state, payload) {
      state.caseNotes = payload;
    },
    setDistributions(state, payload) {
      state.caseDistributions = payload;
    },
    setTransactions(state, payload) {
      state.transactions = payload;
    },
    setHistory(state, payload) {
      state.history = payload;
    },
    setInsurances(state, payload) {
      state.insurances = payload;
    },
    setHeaderLoading(state, payload) {
      state.headerLoading = payload;
    },
    setCaseCounts(state, payload) {
      state.caseCounts = payload;
    },
    setCurrentSpecimen(state, payload) {
      state.currentSpecimen = payload;
    },
    setCasePrefix(state, payload) {
      state.casePrefix = payload;
    },
    clearStore(state) {
      state.caseDetails = {};
      state.currentSpecimen = {};
      state.caseCounts = {};
      state.currentSpecimen = {};
      state.previousCaseDetails = {};
      state.headerLoading = false;
      state.caseHeader = {};
      state.specimens = [];
      state.loading = false;
      state.error = null;
      state.reReportPopup = false;
      state.editType = null;
      state.reasonForChange = null;
      state.caseOrders = [];
      state.caseTags = [];
      state.caseHolds = [];
      state.caseNotes = [];
      state.transactions = [];
      state.caseDistributions = [];
      state.insurances = [];
      state.history = [];
      state.viewedPopups = [];
      state.lastResultsMacro = "";
    },
    clearCaseDetails(state) {
      state.caseDetails = {};
      state.specimens = [];
    },
    setSpecimenToLoad(state, payload) {
      state.specimenToLoad = payload;
    },
    // setSpecimensAfterOrder(state, payload) {
    //   for (const updatedSpecimen of payload) {
    //     const indexToUpdate = state.specimens.findIndex(e => e.id === updatedSpecimen.id);
    //     const { numberOfBlocks, numberOfSlides, cassettes } = updatedSpecimen;
    //     if (state.specimens[indexToUpdate].id === state.currentSpecimen.id) {
    //       state.specimens[indexToUpdate] = {
    //         ...state.currentSpecimen,
    //         numberOfBlocks,
    //         numberOfSlides,
    //         cassettes
    //       };
    //     } else {
    //       state.specimens[indexToUpdate] = {
    //         ...state.specimens[indexToUpdate],
    //         numberOfBlocks,
    //         numberOfSlides,
    //         cassettes
    //       };
    //     }
    //   }
    // },
    setShouldPrintOrders(state, payload) {
      state.shouldPrintOrders = payload;
    },
    setLastResultsMacro(state, payload) {
      state.lastResultsMacro = payload;
    },
    setNextCaseToLoad(state, payload) {
      state.nextCaseToLoad = payload;
    }
  },

  actions: {
    async ammendCase({ commit }, payload) {
      commit("setReasonForChange", payload.reasonForChange);
      commit("setEditType", payload.editType);
      commit("setReReportPopup", false);
    },
    async getCaseCounts({ commit }, caseId) {
      const counts = await CasesApi.getCaseCounts(caseId);
      commit("setCaseCounts", counts);
    },
    async recalculateAutoBilling({ dispatch, state }, payload = []) {
      const response = await Promise.all(
        payload.map(specimenId => SpecimensApi.recalculateAutoBilling(specimenId))
      );
      handleMissingCptPairs(response);
      dispatch("getTransactions", state.caseDetails.caseId);
      dispatch("getCaseCounts", state.caseDetails.caseId);
    },
    //! GET
    async getInsurances({ commit }, caseId) {
      const insurances = await InsuranceApi.getPolicies(caseId);
      commit("setInsurances", insurances);
      return insurances;
    },
    async getTransactions({ commit }, caseId) {
      const transactions = await TransactionsApi.transactionsStore.load({
        filter: ["caseId", caseId]
      });
      commit("setTransactions", transactions);
      return transactions;
    },
    async getHistory({ commit }, [caseId, patientId]) {
      let response = {};
      if (patientId) {
        response = await CasesApi.getPatientCaseHistory(patientId);
      } else {
        response = await CasesApi.getCaseHistory(caseId);
      }
      commit("setHistory", response.data);
      return response;
    },
    async getDistributions({ commit }, caseId) {
      const distributions = await PathReportMessagesApi.getCasePathReportMessages(caseId);
      commit("setDistributions", distributions);
      return distributions;
    },
    async getCaseHeader({ commit }, caseId) {
      commit("setHeaderLoading", true);
      const caseHeader = await CasesApi.getCaseHeader(caseId);
      commit("setCaseHeader", caseHeader);
      commit("setHeaderLoading", false);
      return caseHeader;
    },
    async getCasePrefix({ commit }, prefixId) {
      try {
        const prefix = await PrefixApi.getPrefixById(prefixId);
        commit("setCasePrefix", prefix);
      } catch (error) {
        console.log("Error loading case prefix");
      }
    },
    async getCaseDetails({ commit, state, dispatch }, caseId) {
      const caseDetails = await CasesApi.getCaseById(caseId);
      if (caseId !== state.caseDetails.caseId) {
        await AuditLogApi.insertLogMessage(createLogItem(caseDetails, AuditLogItems.ViewAccession));
        dispatch("getCasePrefix", caseDetails.labPrefix);
      }
      commit("setCaseDetails", caseDetails);
      const primaryContact = caseDetails.contacts.find(e => e.isPrimary);
      if (!primaryContact) {
        window.alert(
          `<b style='font-size:24px;color:red;'>Case ${caseDetails.caseNumber} does not have a primary provider assigned. Please navigate to the Demographics tab and save with a primary provider selected to prevent issues with reporting.</b>`
        );
      }
      return caseDetails;
    },

    async getCaseQuickLinks({ dispatch, commit }, caseId) {
      await Promise.all([dispatch("getCaseOrders", caseId), dispatch("getCaseImages", caseId)]);
      const response = await QuickLinksApi.getCaseQuickLinks(caseId);
      commit("setCaseNotes", response?.notes || []);
      commit("setCaseTags", response?.tags || []);
      commit("setCaseHolds", response?.holds || []);
    },
    async getCaseOrders({ commit, dispatch, state }, caseId) {
      const caseOrders = await ProceduresApi.getCaseProcedures(caseId);
      commit("setCaseOrders", caseOrders);
      if (caseId === state.caseDetails.caseId) {
        await dispatch("getCaseCounts", state.caseDetails.caseId);
      }
      return caseOrders;
    },
    async getCaseSpecimens({ commit }, caseId) {
      const specimens = await SpecimensApi.getSpecimen(caseId);
      if (specimens?.length) {
        commit("setCaseSpecimens", specimens);
        const slideImages = await CasesApi.getSlideImages(caseId);
        if (slideImages.length) {
          commit("setSlideImages", slideImages);
        }
        return specimens;
      } else {
        commit("setCaseSpecimens", []);
        return [];
      }
    },

    async getCaseImages({ commit }, caseId) {
      const response = await CasesApi.getCaseImages(caseId);
      commit("setCaseImages", response);
    },
    //! POST
    async handleSignCase({ dispatch, getters, rootState }, payload) {
      let { caseId, labLocationId } = payload;
      if (!labLocationId) {
        let { currentLabLocation, availableLabLocations } = rootState;
        if (currentLabLocation) {
          labLocationId = currentLabLocation;
        } else if (availableLabLocations?.length) {
          if (availableLabLocations.length === 1) {
            labLocationId = availableLabLocations[0].id;
            dispatch("setCurrentLabLocation", labLocationId, { root: true });
          } else if (availableLabLocations > 1) {
            window.alert("Please select lab location in navigation bar.");
            return;
          }
        } else {
          availableLabLocations = await DropdownApi.getLabLocations();
          dispatch("setAvailableLabLocations", availableLabLocations, { root: true });
          if (!availableLabLocations.length) {
            window.alert("Error loading lab locations.");
            return;
          }
          if (availableLabLocations.length > 1) {
            window.alert("Please select lab location in navigation bar.");
            return;
          }
          if (availableLabLocations.length === 1) {
            labLocationId = availableLabLocations[0].id;
            dispatch("setCurrentLabLocation", labLocationId, { root: true });
          }
        }
      }
      // let batchRedirect = false;
      // IP-1328 - Removes presign logic
      // if (
      //   rootState.applicationSettings.warnIfNoDistribution &&
      //   ![CaseStatusEnum.SignedOnHold, CaseStatusEnum.Signed, CaseStatusEnum.SignedAgain].includes(
      //     payload.caseStatus
      //   )
      // ) {
      //   const { hasBatchDistribution, messageQty } = await CasesApi.presignCase(caseId);
      //   if (!hasBatchDistribution && messageQty === 0) {
      //     const confirmPresign = await window.confirm(
      //       "This case does not have any distribution set up. Do you wish to continue?"
      //     );
      //     if (!confirmPresign) {
      //       return;
      //     }
      //   }
      //   if (hasBatchDistribution && !messageQty) {
      //     batchRedirect = true;
      //   }
      // }
      if (rootState.labSettings.SpecimenHasMultipleICD10SWarning) {
        const caseStatus = await CasesApi.getCaseStatus(caseId);
        if (
          ![
            CaseStatusEnum.SignedOnHold,
            CaseStatusEnum.Signed,
            CaseStatusEnum.SignedAgain
          ].includes(caseStatus)
        ) {
          const specimens = await SpecimensApi.getSpecimen(caseId);
          let specimensWithMultipleIds = [];
          for (const specimen of specimens) {
            if (specimen.icdCodes.length > 1) {
              specimensWithMultipleIds.push(specimen.specimenOrder);
            }
          }
          if (specimensWithMultipleIds.length) {
            const isPlural = specimensWithMultipleIds.length > 1;
            const confirm = await window.confirm(
              `Specimen${isPlural ? "s" : ""} ${specimensWithMultipleIds.join(", ")} ${
                isPlural ? "have" : "has"
              } multiple ICD10 codes. Are you sure you want to sign this case out?`
            );
            if (!confirm) {
              return;
            }
          }
        }
      }
      const SignResponse = await CasesApi.signCase(caseId, labLocationId);
      const isSigned = [
        CaseStatusEnum.SignedOnHold,
        CaseStatusEnum.Signed,
        CaseStatusEnum.SignedAgain
      ].includes(SignResponse.status);
      const logItem = createLogItem(SignResponse, AuditLogItems.ChangeAccession);
      logItem.comments = `${isSigned ? "Signed out" : "Unsigned"} case ${SignResponse.caseNumber}.`;
      AuditLogApi.insertLogMessage(logItem);
      if (!payload?.skipRefresh) {
        await dispatch("getCaseHeader", caseId);
        await dispatch("getCaseDetails", caseId);
        await dispatch("report/viewPathReport", { caseId }, { root: true });
      }
      if (getters.isSigned && SignResponse) {
        return SignResponse;
      }
      return false;
    },
    async insertCaseSpecimen({ commit, rootState, state, dispatch }, payload) {
      const { ForceUpperCaseSite } = rootState.labSettings;
      if (ForceUpperCaseSite && payload.site) {
        payload.site = payload.site.toUpperCase();
      }
      if (state.editType && state.reasonForChange) {
        payload.editType = this.editType;
        payload.reasonForChange = this.reasonForChange;
      }
      if (payload.gross && payload.site) {
        payload.gross = payload.gross.replace(/@site@/gi, payload.site);
      }
      sanitizeHTML(payload, rootState.labSettings);
      const specimen = await SpecimensApi.createSpecimen({
        clinical: payload.clinical,
        specimenOrder: payload.specimenOrder,
        gross: payload.gross,
        site: payload.site,
        protocolId: payload.protocolId,
        clinicalICDCode: payload.clinicalICDCode,
        caseNumber: state.caseDetails.caseNumber,
        caseId: state.caseDetails.caseId,
        pathologists: state.caseDetails.pathologists,
        contacts: state.caseDetails.contacts,
        numberOfBlocks: 1,
        caseNotes: payload.caseNotes
      });
      handleMissingCptPairs(specimen);
      //Sets the current session lastUSed side & protocol.
      commit("sessionDetails/setLastUsedSite", payload.site, { root: true });
      commit("sessionDetails/setLastUsedProtocol", payload.protocolId, { root: true });
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Added specimen ${specimen.specimenOrder}. \n ${JSON.stringify(
        payload,
        null,
        2
      )}`;
      if (state.editType) {
        logItem.comments += `\n Edit Type -- ${state.editTypes[state.editType]}`;
      }
      if (state.reasonForChange) {
        logItem.comments += `\n Reason For Change -- ${state.reasonForChange}`;
      }
      if (state.editType && state.reasonForChange) {
        commit("setEditType", null);
        commit("setReasonForChange", null);
      }
      AuditLogApi.insertLogMessage(logItem);
      await Promise.all([
        dispatch("getCaseHeader", state.caseDetails.caseId),
        dispatch("getCaseSpecimens", state.caseDetails.caseId),
        dispatch("getCaseQuickLinks", state.caseDetails.caseId),
        dispatch("getCaseCounts", state.caseDetails.caseId)
      ]);
      await dispatch("getCaseDetails", state.caseDetails.caseId);

      return specimen;
    },
    async upsertCaseQuickLink({ dispatch, state }, payload) {
      let isHolds = false;
      if (Array.isArray(payload)) {
        const response = payload.map(item => {
          if (item?.type === "H") {
            isHolds = true;
          }
          if (item.id) {
            return QuickLinksApi.editNoteTags(item);
          } else {
            return QuickLinksApi.addNoteTags(item);
          }
        });
        await Promise.all(response);
        const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
        logItem.comments = `Added ${payload.length} quick link items. ${JSON.stringify(
          payload,
          null,
          2
        )}`;
        await AuditLogApi.insertLogMessage(logItem);
      } else {
        if (payload?.type === "H") {
          isHolds = true;
        }
        if (payload.id) {
          await QuickLinksApi.editNoteTags(payload);
        } else {
          await QuickLinksApi.addNoteTags(payload);
        }
        const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
        logItem.comments = `Added a ${getQuickLinkType(payload)}, \n${JSON.stringify(
          payload,
          null,
          2
        )}`;
        await AuditLogApi.insertLogMessage(logItem);
      }
      const caseId = state.caseDetails?.caseId;
      if (isHolds && caseId) {
        await dispatch("report/viewPathReport", { caseId }, { root: true });
      }
      await dispatch("getCaseQuickLinks", state.caseDetails.caseId);
      await dispatch("getCaseHeader", state.caseDetails.caseId);
    },
    async updateCaseOrder({ dispatch, state, commit }, payload) {
      const response = await ProceduresApi.updateCaseProcedure(payload);
      const specIds = payload.items.reduce((acc, item) => {
        if (!acc.includes(item.specimenId)) {
          acc.push(item.specimenId);
        }
        return acc;
      }, []);

      const specimens = await SpecimensApi.getSpecimen(state.caseDetails.caseId);
      commit("setCaseSpecimens", specimens);
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Updated procedure on case. \n ${state.caseDetails.caseNumber}`;
      AuditLogApi.insertLogMessage(logItem);
      if (specIds.length) {
        dispatch("recalculateAutoBilling", specIds);
      }
      dispatch("getCaseOrders", state.caseDetails.caseId);
      dispatch("getCaseHeader", state.caseDetails.caseId);
      return response;
    },
    async addCaseOrders({ dispatch, state, commit }, payload) {
      const response = await ProceduresApi.applyProcedures(payload);
      const specIds = payload.items.reduce((acc, item) => {
        if (!acc.includes(item.specimenId)) {
          acc.push(item.specimenId);
        }
        return acc;
      }, []);
      const specimens = await SpecimensApi.getSpecimen(state.caseDetails.caseId);
      const currentSpecimen = { ...(state.currentSpecimen ?? {}) };
      const newCurrentSpecimenIdx = specimens.findIndex(e => e.id === currentSpecimen.id);

      if (newCurrentSpecimenIdx > -1) {
        const { numberOfBlocks, numberOfSlides, cassettes } = specimens[newCurrentSpecimenIdx];
        currentSpecimen.numberOfBlocks = numberOfBlocks;
        currentSpecimen.numberOfSlides = numberOfSlides;
        currentSpecimen.cassettes = cassettes;
        commit("setCurrentSpecimen", currentSpecimen);
      }
      commit("setCaseSpecimens", specimens);
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Added procedures to the case. \n ${JSON.stringify(payload, null, 2)}`;

      await dispatch("getCaseQuickLinks", state.caseDetails.caseId);
      await Promise.all([
        dispatch("getCaseHeader", state.caseDetails.caseId),
        dispatch("recalculateAutoBilling", specIds),
        AuditLogApi.insertLogMessage(logItem)
      ]);
      return response;
    },

    async insertImage({ dispatch, state }, payload) {
      const response = await ImagesApi.addImage(payload);
      dispatch("getCaseImages", state.caseDetails.caseId);
      return response;
    },
    //! PUT
    async updateImage({ dispatch, state }, payload) {
      await ImagesApi.updateImage(payload);
      dispatch("getCaseImages", state.caseDetails.caseId);
    },
    async updateCaseDetails({ commit, state, dispatch }, newCaseDetails) {
      const payload = {
        ...newCaseDetails,
        editType: state.editType,
        reasonForChange: state.reasonForChange
      };
      const updatedCase = await CasesApi.updateCase(payload);
      const logItem = createLogItem(newCaseDetails, 1);
      //Compares the two objects and returns a string outlying the chases
      logItem.comments = createLogComment(
        {
          ...state.caseDetails,
          patientAccountNumber: state.caseDetails.patientAccountNumber,
          specimens: [],
          numberOfSpecimens: 0,
          prefix: null,
          qtyProcedures: 0,
          private: state.caseDetails.private,
          isPrivate: state.caseDetails.private
        },
        {
          ...newCaseDetails,
          patientAccountNumber: newCaseDetails.patientAccountNumber,
          specimens: [],
          numberOfSpecimens: 0,
          prefix: null,
          private: state.caseDetails.private,
          isPrivate: newCaseDetails.isPrivate,
          qtyProcedures: 0
        }
      );

      if (state.editType) {
        logItem.comments += `\n Edit Type -- ${state.editTypes[state.editType]}`;
      }
      if (state.reasonForChange) {
        logItem.comments += `\n Reason For Change -- ${state.reasonForChange}`;
      }
      AuditLogApi.insertLogMessage(logItem);

      if (state.editType && state.reasonForChange) {
        commit("setEditType", null);
        commit("setReasonForChange", null);
      }
      commit("setCaseDetails", updatedCase);
      dispatch("getCaseHeader", updatedCase.caseId);
      dispatch("getCaseDetails", updatedCase.caseId);
      return updatedCase;
    },
    async increaseSpecimenCassetteQty({ state, commit, dispatch }, payload) {
      const pieceMap = payload.cassettes.map(e => {
        return { blockNum: e.blockNum, qtyPieces: e.qtyPieces };
      });
      await SpecimensApi.increaseCassetteQty(payload.id, payload.numberOfBlocks);
      const caseSpecimens = await SpecimensApi.getSpecimen(state.caseDetails.caseId);
      let targetSpecimen = caseSpecimens.find(specimen => specimen.id === payload.id);
      for (const pieceCount of pieceMap) {
        const targetIndex = targetSpecimen.cassettes
          .map(e => e.blockNum)
          .indexOf(pieceCount.blockNum);
        if (targetIndex > -1) {
          targetSpecimen.cassettes[targetIndex].qtyPieces = pieceCount.qtyPieces;
          state.currentSpecimen = targetSpecimen;
        }
      }
      commit(
        "setCaseSpecimens",
        state.specimens.map(specimen => {
          if (payload.id === specimen.id) {
            return targetSpecimen;
          }
          return specimen;
        })
      );
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Increased block number on ${payload.specimenOrder}.`;

      AuditLogApi.insertLogMessage(logItem);

      return dispatch("getCaseOrders", state.caseDetails.caseId);
    },

    async updateCaseSpecimen({ commit, rootState, state, dispatch }, payload) {
      const { ForceUpperCaseSite } = rootState.labSettings;
      if (ForceUpperCaseSite && payload.site) {
        payload.site = payload.site.toUpperCase();
      }

      if (state.editType && state.reasonForChange) {
        payload.editType = state.editType;
        payload.reasonForChange = state.reasonForChange;
        commit("setEditType", null);
        commit("setReasonForChange", null);
      }
      if (payload.gross && payload.site) {
        payload.gross = payload.gross.replace(/@site@/gi, payload.site);
      }
      sanitizeHTML(payload, rootState.labSettings);
      if (!payload?.isICDModified) {
        for (const icdCode of payload.icdCodes) {
          if (!icdCode.isManual) {
            const response = await IcdRulesApi.findBillableCode({
              caseReceivedOn: state.caseDetails.receivedOn,
              searchFor: icdCode.displayCode.replace(/\.([0-9]+)/gi, ""),
              specimenDiagnosis: payload.diagnosis,
              specimenSite: payload.site ?? ""
            });
            if (response.replacementIcdCode && response.replacementIcdCode?.id !== icdCode.id) {
              icdCode.isDeleted = true;
              payload.icdCodes = removeDuplicates(
                [...payload.icdCodes, response.replacementIcdCode],
                "id"
              );
            }
          }
        }
      }

      const originalSpecimen = state.specimens.find(s => s.id === payload.id);

      //!! Logic for increasing the number of blocks on a case.
      if (
        originalSpecimen.numberOfBlocks > 0 &&
        originalSpecimen.numberOfBlocks !== payload.numberOfBlocks &&
        payload.protocolId === originalSpecimen.protocolId
      ) {
        await dispatch("increaseSpecimenCassetteQty", payload);
        const specimenIndex = state.specimens.map(e => e.id).indexOf(payload.id);
        payload.cassettes = state.specimens[specimenIndex].cassettes;
      }

      const updatedSpecimen = await SpecimensApi.updateSpecimen([
        { ...payload, caseId: state.caseDetails.caseId }
      ]);
      handleMissingCptPairs(updatedSpecimen);
      const hasChanges = JSON.stringify(originalSpecimen) !== JSON.stringify(payload);
      if (hasChanges) {
        if (hasChanges) {
          const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
          logItem.comments = createLogComment(
            { ...originalSpecimen, cassettes: [], pathologists: [] },
            { ...payload, cassettes: [], pathologists: [] }
          );

          await AuditLogApi.insertLogMessage(logItem);
        }
      }
      dispatch("getCaseSpecimens", state.caseDetails.caseId);
      dispatch("getCaseOrders", state.caseDetails.caseId);
      dispatch("getCaseQuickLinks", state.caseDetails.caseId);
      return updatedSpecimen;
    },
    async resultCaseSpecimens({ commit, rootState, state, dispatch }, payload) {
      dispatch("logTimeSinceBase", "started store function", { root: true });
      const { ForceUpperCaseSite } = rootState.labSettings;
      for (const specimen of payload) {
        sanitizeHTML(specimen, rootState.labSettings);
        if (ForceUpperCaseSite && specimen.site) {
          specimen.site = specimen.site.toUpperCase();
        }
        if (state.editType && state.reasonForChange) {
          specimen.editType = state.editType;
          specimen.reasonForChange = state.reasonForChange;
        }
        if (!specimen?.isICDModified) {
          for (const icdCode of specimen.icdCodes || []) {
            if (icdCode.isDeleted) {
              continue;
            }
            if (!icdCode.isManual) {
              dispatch("logTimeSinceBase", "waiting for icd in accession store", { root: true });
              const response = await IcdRulesApi.findBillableCode({
                caseReceivedOn: state.caseDetails.receivedOn,
                searchFor: icdCode.displayCode.replace(/\.([0-9]+)/gi, ""),
                specimenDiagnosis: specimen.diagnosis,
                specimenSite: specimen.site ?? ""
              });
              dispatch("logTimeSinceBase", "icd done", { root: true });
              if (response.replacementIcdCode && response.replacementIcdCode?.id !== icdCode.id) {
                icdCode.isDeleted = true;
                specimen.icdCodes = removeDuplicates(
                  [...specimen.icdCodes, response.replacementIcdCode],
                  "id"
                );
              }
            }
          }
        }
      }
      dispatch("logTimeSinceBase", "started update specimen", { root: true });
      const updatedSpecimen = await SpecimensApi.updateSpecimen(payload);
      dispatch("logTimeSinceBase", "finish update specimen", { root: true });
      handleMissingCptPairs(updatedSpecimen);
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      dispatch("logTimeSinceBase", "startget specimens", { root: true });
      const updatedCaseSpecimens = await SpecimensApi.getSpecimen(state.caseDetails.caseId);
      dispatch("logTimeSinceBase", "end get specimens", { root: true });
      updatedCaseSpecimens.forEach(specimen => {
        const originalSpecimen = state.specimens.find(s => s.id === specimen.id);
        logItem.comments += `${specimen.specimenOrder}: ${createLogComment(
          { ...originalSpecimen, cassettes: [], numberOfSlides: 0, prefixId: null },
          { ...specimen, cassettes: [], numberOfSlides: 0, prefixId: null }
        )}`;
      });
      if (state.editType) {
        logItem.comments += `\nEdit Type -- ${state.editTypes[state.editType]}`;
      }
      if (state.reasonForChange) {
        logItem.comments += `\nReason For Change -- ${state.reasonForChange}`;
      }
      commit("setCaseSpecimens", updatedCaseSpecimens);
      dispatch("logTimeSinceBase", "sent audit log", { root: true });
      AuditLogApi.insertLogMessage(logItem).then(() =>
        dispatch("logTimeSinceBase", "audit log done", { root: true })
      );
      if (state.editType && state.reasonForChange) {
        commit("setEditType", null);
        commit("setReasonForChange", null);
      }
      dispatch("getCaseHeader", state.caseDetails.caseId);
      dispatch("getCaseDetails", state.caseDetails.caseId);
      dispatch("getCaseCounts", state.caseDetails.caseId);
      dispatch("logTimeSinceBase", "finished store function", { root: true });
    },
    async resultSingleSpecimen({ commit, rootState, state, dispatch }, specimen) {
      const { ForceUpperCaseSite } = rootState.labSettings;
      sanitizeHTML(specimen, rootState.labSettings);
      if (ForceUpperCaseSite && specimen.site) {
        specimen.site = specimen.site.toUpperCase();
      }
      if (state.editType && state.reasonForChange) {
        specimen.editType = state.editType;
        specimen.reasonForChange = state.reasonForChange;
      }
      if (!specimen?.isICDModified) {
        for (const icdCode of specimen.icdCodes || []) {
          if (icdCode.isDeleted) {
            continue;
          }
          if (!icdCode.isManual) {
            const response = await IcdRulesApi.findBillableCode({
              caseReceivedOn: state.caseDetails.receivedOn,
              searchFor: icdCode.displayCode.replace(/\.([0-9]+)/gi, ""),
              specimenDiagnosis: specimen.diagnosis,
              specimenSite: specimen.site ?? ""
            });
            if (response.replacementIcdCode && response.replacementIcdCode?.id !== icdCode.id) {
              icdCode.isDeleted = true;
              specimen.icdCodes = removeDuplicates(
                [...specimen.icdCodes, response.replacementIcdCode],
                "id"
              );
            }
          }
        }
      }
      const updatedSpecimen = await SpecimensApi.updateSpecimen([specimen]);
      handleMissingCptPairs(updatedSpecimen);
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      const updatedCaseSpecimens = await SpecimensApi.getSpecimen(state.caseDetails.caseId);
      const targetSpecimen = updatedCaseSpecimens.find(e => e.id === specimen.id);

      const originalSpecimen = state.specimens.find(s => s.id === targetSpecimen.id);

      logItem.comments += `${specimen.specimenOrder}: ${createLogComment(
        { ...originalSpecimen, cassettes: [], numberOfSlides: 0, prefixId: null },
        { ...specimen, cassettes: [], numberOfSlides: 0, prefixId: null }
      )}`;

      if (state.editType) {
        logItem.comments += `\nEdit Type -- ${state.editTypes[state.editType]}`;
      }
      if (state.reasonForChange) {
        logItem.comments += `\nReason For Change -- ${state.reasonForChange}`;
      }

      AuditLogApi.insertLogMessage(logItem);

      commit(
        "setCaseSpecimens",
        state.specimens.map(e => {
          if (e.id === targetSpecimen.id) {
            return targetSpecimen;
          }
          return e;
        })
      );
      if (state.editType && state.reasonForChange) {
        commit("setEditType", null);
        commit("setReasonForChange", null);
      }
      dispatch("getCaseHeader", state.caseDetails.caseId);
      dispatch("getCaseCounts", state.caseDetails.caseId);
      dispatch("getCaseQuickLinks", state.caseDetails.caseId);
    },
    //! Delete
    async removeCaseImage({ dispatch, state }, payload) {
      await ImagesApi.deleteImage(payload);
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Removed image ${payload.id} `;
      await AuditLogApi.insertLogMessage(logItem);
      return dispatch("getCaseImages", state.caseDetails.caseId);
    },
    async removeCaseTx({ dispatch, state }, payload) {
      await TransactionsApi.deleteTx(payload.id);
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Removed transactions ${payload.code} `;
      await AuditLogApi.insertLogMessage(logItem);
      if (payload.caseId == state.caseDetails.caseId) {
        dispatch("getCaseCounts", state.caseDetails.caseId);
        dispatch("getTransactions", state.caseDetails.caseId);
      }
    },
    async removeCaseSpecimen({ commit, state, dispatch }, payload) {
      if (payload.id) {
        try {
          const deletedSpecimen = await SpecimensApi.deleteSpecimen(payload.id);
          handleMissingCptPairs(deletedSpecimen);
          commit(
            "setCaseSpecimens",
            state.specimens.filter(specimen => specimen.id !== payload.id)
          );
          dispatch("getCaseCounts", state.caseDetails.caseId);
        } catch (error) {
          handleErrors(error);
        }
      } else {
        commit(
          "setCaseSpecimens",
          state.specimens.filter(specimen => specimen.specimenOrder !== payload.specimenOrder)
        );
      }
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Removed specimen ${payload.specimenOrder} on case`;

      AuditLogApi.insertLogMessage(logItem);
    },
    async removeCaseHold({ state, dispatch }, payload) {
      await QuickLinksApi.deleteQuickLinks(payload);

      const logItem = createLogItem(state.caseDetails, 1);
      logItem.comments = `Removed hold on case \n ${JSON.stringify(payload, null, 2)}`;

      AuditLogApi.insertLogMessage(logItem);
      dispatch("getCaseHeader", state.caseDetails.caseId);
      dispatch("getCaseQuickLinks", state.caseDetails?.caseId);
    },
    async removeCaseOrder({ commit, state, dispatch }, payload) {
      await ProceduresApi.removeCaseProcedure({
        cassetteId: payload.cassetteId,
        procedureOrPanelId: payload.procedureId,
        idType: 1
      });
      commit(
        "setCaseOrders",
        state.caseOrders.filter(order => {
          if (
            order.cassetteId === payload.cassetteId &&
            order.procedureId === payload.procedureId
          ) {
            return false;
          }
          return true;
        })
      );
      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Removed an order on cassette ${payload.cassetteId} procedure ${payload.procedureId}`;

      AuditLogApi.insertLogMessage(logItem);
      dispatch("getCaseHeader", state.caseDetails.caseId);
    },
    async removeQuickLink({ state, dispatch }, payload) {
      await QuickLinksApi.deleteQuickLinks(payload);

      const logItem = createLogItem(state.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Removed ${getQuickLinkType(payload)} on case`;

      AuditLogApi.insertLogMessage(logItem);
      await dispatch("getCaseQuickLinks", state.caseDetails.caseId || payload.caseId);
      if (payload?.type === "H" && payload?.caseId) {
        await dispatch("report/viewPathReport", { caseId: payload.caseId }, { root: true });
      }
    },
    async runIcdEngine({ state }, payload) {
      if (payload?.specimen?.isICDModified) {
        return payload.specimen;
      }
      eventBus.$emit(ICD_ENGINE_START);
      let { specimen, macro } = payload;
      const icdEngineCalls = macro.icdCodes.map(async code => {
        if (
          moment(code.expiryOn).isBefore(new Date()) ||
          moment(code.effectiveOn).isAfter(new Date())
        ) {
          window.notify(
            `Icd code outside of effective range found ${code.displayCode}.`,
            "warning"
          );
        }
        const response = await IcdRulesApi.findBillableCode({
          caseReceivedOn: state.caseDetails.receivedOn,
          searchFor: code.displayCode.replace(/\.([0-9]+)/gi, ""),
          specimenDiagnosis: specimen.diagnosis,
          specimenSite: specimen.site ?? ""
        });
        return [response, code];
      });
      const icdEngineReponses = await Promise.all(icdEngineCalls);
      icdEngineReponses.forEach(([response, code]) => {
        const nonBillableCodes = [];
        let newCodes = macro.icdCodes;
        if ([3, 4, 6, 3, 5, 7, 8].includes(response.detail) && !code.isBillable) {
          nonBillableCodes.push(code.displayCode);
        } else if ([2].includes(response.detail)) {
          const { replacementIcdCode } = response;
          newCodes = newCodes.map(icd => {
            if (icd.id === code.id && replacementIcdCode !== icd.id) {
              return replacementIcdCode;
            }
            return icd;
          });
        }
        if (nonBillableCodes.length) {
          const message = `Non billable icd code found on specimen ${
            specimen.specimenOrder
          }.<br> Code(s): ${nonBillableCodes.join(", ")}`;
          window.alert(message);
        }
        specimen.icdCodes = uniqBy([...(specimen?.icdCodes || []), ...newCodes], "id");
      });
      eventBus.$emit(ICD_ENGINE_FINISH);
      return specimen;
    },
    async mergeSpecimensWithMacros({ dispatch, commit, state }, payload) {
      const { event, specimens } = payload;
      const mergedSpecimens = await mergeSpecimensWithMacros(
        specimens,
        event.macros,
        event.prefix,
        event.fieldName
      );
      const icdEngine = [];
      for (let specimen of mergedSpecimens) {
        if (specimen.icdEngineMacros?.length && !specimen?.isICDModified) {
          for (const macro of specimen.icdEngineMacros) {
            icdEngine.push(
              dispatch("runIcdEngine", { specimen, macro }).then(output => {
                if (JSON.stringify(specimen.icdCodes) !== JSON.stringify(output.icdCodes)) {
                  specimen.icdCodes = output.icdCodes;
                }
                unset(specimen, "icdEngineMacros");
              })
            );
          }
        }
      }
      await Promise.all(icdEngine);
      const caseHoldCodeTypeIds = state.caseHolds.map(holdCode => holdCode?.tagId);
      const caseTagIds = state.caseTags.map(tag => tag?.tagId);
      for (const macro of event.macros) {
        if (macro.holdCodes?.length) {
          macro.holdCodes.forEach(holdCodeId => {
            if (Object.hasOwnProperty.call(holdCodeId, "id")) {
              holdCodeId = holdCodeId.id;
            }
            const isExisting = caseHoldCodeTypeIds.includes(holdCodeId);
            if (!isExisting) {
              dispatch("upsertCaseQuickLink", {
                type: "H",
                tagId: holdCodeId,
                caseId: state.caseDetails.caseId,
                text: `Added by Macro: ${macro.macroName}.`
              });
            }
          });
          if (macro.tags?.length) {
            macro.tags.forEach(tagId => {
              if (Object.hasOwnProperty.call(tagId, "id")) {
                tagId = tagId.id;
              }
              const isExisting = caseTagIds.includes(tagId);
              if (!isExisting) {
                dispatch("upsertCaseQuickLink", {
                  type: "T",
                  tagId: tagId,
                  caseId: state.caseDetails.caseId,
                  text: `Added by Macro: ${macro.macroName}`
                });
              }
            });
          }
        }
      }
      const updatedSpecimens = uniqBy([...mergedSpecimens, ...state.specimens], "specimenOrder");
      commit("setCaseSpecimens", updatedSpecimens);

      return updatedSpecimens;
    },
    async useMacroOnCurrentSpecimen({ state, commit, dispatch }, { macros, name, prefix }) {
      const specimen = state.currentSpecimen;
      if (/frozen/i.test(name)) {
        return;
      }
      if (!prefix) {
        prefix = macros.map(macro => `.${macro.macroName}`).join("");
      }
      const caseHoldCodeTypeIds = state.caseHolds.map(holdCode => holdCode?.tagId);
      const caseTagIds = state.caseTags.map(holdCode => holdCode?.tagId);
      if (name) {
        const [updateSpecimen] = await mergeSpecimensWithMacros([specimen], macros, prefix);
        const macroEnabledFields = [
          "clinical",
          "gross",
          "results",
          "caseNotes",
          "diagnosis",
          "specimenNotes",
          "notes",
          "microscopic",
          "generalText",
          "diagnosisSummaryId",
          "cptCodes",
          "interfaceDiagnosis",
          "icdCodes",
          "procedures"
        ];
        macroEnabledFields.forEach(prop => {
          if (prop !== name && prop !== "resultsMacros") {
            specimen[prop] = updateSpecimen[prop];
          }
        });
      }
      for (const macro of macros) {
        if (macro.tags?.length) {
          macro.tags.forEach(tagId => {
            if (Object.hasOwnProperty.call(tagId, "id")) {
              tagId = tagId.id;
            }
            const isExisting = caseTagIds.includes(tagId);
            if (!isExisting) {
              dispatch("upsertCaseQuickLink", {
                type: "T",
                tagId: tagId,
                caseId: specimen.caseId,
                text: `Added by Macro: ${macro.macroName} on Specimen: ${specimen.specimenOrder}`
              });
            }
          });
        }
        if (macro.holdCodes?.length) {
          macro.holdCodes.forEach(holdCodeId => {
            if (Object.hasOwnProperty.call(holdCodeId, "id")) {
              holdCodeId = holdCodeId.id;
            }
            const isExisting = caseHoldCodeTypeIds.includes(holdCodeId);
            if (!isExisting) {
              dispatch("upsertCaseQuickLink", {
                type: "H",
                tagId: holdCodeId,
                caseId: specimen.caseId,
                text: `Added by Macro: ${macro.macroName} on Specimen: ${specimen.specimenOrder}`
              });
            }
          });
        }
        if (
          [MacroTypeEnum.Results, MacroTypeEnum.Diagnosis].includes(macro.macroType) &&
          name &&
          !macro?.textOnly
        ) {
          specimen.resultsMacros = [
            ...(specimen?.resultsMacros || []),
            {
              id: macro.macroId,
              displayName: macro.macroName,
              isDeleted: false,
              macroType: macro.macroType
            }
          ];
        }
        if (macro.icdCodes?.length && !specimen?.isICDModified) {
          dispatch("runIcdEngine", {
            specimen: specimen,
            macro
          }).then(response => {
            specimen.icdCodes = response.icdCodes;
            commit("setCurrentSpecimen", specimen);
          });
        }
      }
      commit("setCurrentSpecimen", specimen);
    },
    clearGhostSpecimens({ state }) {
      const specimensWithId = state.specimens.filter(e => e?.id);
      if (state.specimens.length !== specimensWithId.length) {
        state.specimens = specimensWithId;
      }
    },
    async autoPrintOrders({ state, dispatch, commit }, caseId) {
      commit("setShouldPrintOrders", false);
      try {
        await CasesApi.autoPrintProcedures(caseId);
        if (state.caseOrders.length && state.caseDetails?.caseId === caseId) {
          dispatch("getCaseOrders", caseId);
        }
      } catch (error) {
        if (error?.response?.data?.message.includes("500")) {
          window.notify("Orders could not print due to an issue with the report", "error");
          console.log(error);
        } else {
          window.notify("Error printing orders.", "error");
          console.log(error);
        }
      }
    },
    async updateProstateSpecimenSites({ state, commit }, payload) {
      const initialSpecimens = cloneDeep(state.specimens);
      await SpecimensApi.updateSpecimen(payload);
      const updatedSpecimens = await SpecimensApi.getSpecimen(state.caseDetails.caseId);
      let newSpecimens = [];
      for (const specimen of initialSpecimens) {
        const updatedSpecimen = updatedSpecimens.find(
          e => e.specimenOrder === specimen.specimenOrder
        );
        if (updatedSpecimen) {
          newSpecimens.push(updatedSpecimen);
        } else {
          newSpecimens.push(specimen);
        }
      }
      commit("setCaseSpecimens", newSpecimens);
    },
    async updateProstateSpecimens({ state, dispatch, commit }, payload) {
      const currentSpecimenData = payload.find(e => e.id === state.currentSpecimen?.id);
      payload = payload.map(specimen => {
        const originalSpecimen = state.specimens.find(e => e.id === specimen.id);
        return { ...originalSpecimen, ...specimen };
      });
      await SpecimensApi.updateSpecimen(payload);
      await dispatch("getCaseSpecimens", state.caseDetails.caseId);
      if (currentSpecimenData?.id) {
        commit("setCurrentSpecimen", { ...state.currentSpecimen, ...currentSpecimenData });
        eventBus.$emit(UPDATE_CURRENT_SPECIMEN_PROSTATE, currentSpecimenData);
      }
    }
  }
};

function getQuickLinkType(item) {
  if (!item) {
    return null;
  }
  switch (item.type) {
    case "T":
      return "tag";
    case "H":
      return "Hold Code";
    case "N":
      return "Note";
  }
}
