import { ImagesApi, QuickLinksApi } from "../services/index";

export const sessionDetails = {
  namespaced: true,
  state: () => ({
    accessions: 0,
    accessionIds: [],
    specimens: 0,
    labelPrinterOverride: false,
    labelPrintAmount: 1,
    lastProvider: null,
    lastPathologist: null,
    lastPrefix: null,
    lastAccession: {},
    lastUsedSite: null,
    lastUsedProtocol: null,
    isRecordingLogRocket: false,
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    imageFrame: {
      isOpen: false,
      urls: []
    },
    viewedSlideImages: [],
    activeSpellCheckers: [],
    scannedReqImages: [],
    scannedReqCounts: {
      cases: 0,
      images: 0
    }
  }),

  mutations: {
    setLastUsedSite(state, payload) {
      state.lastUsedSite = payload;
    },
    setAccessionIds(state, payload) {
      state.accessionIds = payload;
    },
    setLastUsedProtocol(state, payload) {
      state.lastUsedProtocol = payload;
    },
    setLastPrefix(state, payload) {
      state.lastPrefix = payload;
    },
    setLastProvider(state, payload) {
      state.lastProvider = payload;
    },
    setLastPathologist(state, payload) {
      state.lastPathologist = payload;
    },
    setLastAccession(state, payload) {
      state.lastAccession = payload;
    },
    setViewedSlideImages(state, payload) {
      //! Set a max size of 25 ids to prevent memory leaks
      if (payload.length > 25) {
        payload = payload.slice(0, 25);
      }
      const payloadUniq = new Set(payload);
      state.viewedSlideImages = Array.from(payloadUniq);
    },
    setCurrentQuickLinks(state, payload) {
      state.quickLinks = payload;
    },
    setSessionDetails(state, labDetails = {}) {
      state.accessions = labDetails.accessions;
      state.specimens = labDetails.specimens;
      state.labelPrinterOverride = labDetails.labelPrinterOverride;
      state.labelPrintAmount = labDetails.labelPrintAmount;
      state.lastProvider = labDetails.lastProvider;
      state.lastPathologist = labDetails.lastPathologist;
      state.lastPrefix = labDetails.lastPrefix;
      state.lastAccession = labDetails.lastAccession;
    },
    clearSessionDetails(state) {
      const defaultState = {
        accessions: 0,
        accessionIds: [],
        specimens: 0,
        labelPrinterOverride: false,
        labelPrintAmount: 1,
        lastProvider: null,
        lastPathologist: null,
        lastPrefix: null,
        lastAccession: {},
        lastUsedSite: null,
        lastUsedProtocol: null,
        scannedReqImages: []
      };

      Object.keys(defaultState).forEach(key => {
        state[key] = defaultState[key];
      });
    },
    setLabelPrintOverride(state, payload) {
      state.labelPrinterOverride = payload;
    },
    setLabelPrintAmount(state, payload) {
      state.labelPrintAmount = payload;
    },
    setAccessions(state, payload) {
      state.accessions = payload;
    },
    setSpecimens(state, payload) {
      state.specimens = payload;
    },
    setIsRecordingLogRocket(state, payload) {
      state.isRecordingLogRocket = payload;
    },
    setImageFrameStatus(state, payload) {
      state.imageFrame.isOpen = payload;
    },
    setImageFrameData(state, payload) {
      state.imageFrame.urls = payload;
    },
    setActiveSpellCheckers(state, { name, isAdding }) {
      if (isAdding) {
        if (!state.activeSpellCheckers.includes(name)) {
          state.activeSpellCheckers.push(name);
        }
      } else {
        state.activeSpellCheckers = state.activeSpellCheckers.filter(e => e !== name);
      }
    },
    setScannedReqImages(state, payload) {
      state.scannedReqImages = payload;
    },
    setScannedReqCounts(state, payload) {
      state.scannedReqCounts = payload;
    }
  },

  actions: {
    async useImageFrame({ commit }, payload) {
      commit("setImageFrameStatus", true);
      commit("setImageFrameData", []);
      const imageFiles = payload.map(async imageId => {
        const fileBuffer = await ImagesApi.getImageContent(imageId);
        return {
          imageSrc: URL.createObjectURL(fileBuffer),
          type: fileBuffer.type
        };
      });
      const imageUrls = await Promise.all(imageFiles);
      commit("setImageFrameData", imageUrls);
    },

    async accessionCase({ commit, state, rootState }, previousCase) {
      const { labPrefix, contacts, caseNumber, patientLastName, patientFirstName, caseId } =
        previousCase;
      const primaryProvider = contacts.find(e => e.isPrimary);

      if (labPrefix) {
        commit("setLastPrefix", null);
        const prefix = rootState.dropdowns.prefixes.find(prefix => prefix.id === labPrefix);
        if (prefix) {
          commit("setLastPrefix", prefix);
        }
      }
      if (state.lastAccession.caseId !== previousCase.caseId) {
        commit("setLastAccession", { caseNumber, patientLastName, patientFirstName, caseId });
        commit("setAccessions", state.accessions + 1);
      }
      commit("setAccessionIds", [...state.accessionIds.slice(0, 8), caseId]);
      commit("setLastProvider", primaryProvider);
    },
    async getRoleQuickLinks({ commit }) {
      const quickLinks = await QuickLinksApi.quickLinksByRole();
      commit("setCurrentQuickLinks", quickLinks);
    },
    startRecordingLogRocket({ commit }) {
      commit("isRecordingLogRocket", true);
    },
    increaseSpecimenCount({ commit, state }, count) {
      const prevCount = state.specimens;
      commit("setSpecimens", prevCount + count);
    },
    addReqImageScans({ commit }, formData) {
      ImagesApi.uploadScans(formData).then(res => {
        const imagesWithoutIds = res.imagesUploads.find(e => !e.caseId);
        if (imagesWithoutIds) {
          window.alert(
            "One or more barcodes on scanned reqs could not be read. Please check your data."
          );
        } else {
          window.notify("Finished scanning reqs.");
        }
        commit("setScannedReqImages", res.imagesUploads);
        commit("setScannedReqCounts", { cases: res.casesUpdloaded, images: res.imagesUploaded });
      });
    }
  }
};
