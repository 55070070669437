<template>
  <div class="text-input">
    <label
      v-html="displayName(accessKey, label)"
      v-if="label && !noLabel"
      :for="$attrs.id || name"
      :id="labelId"
    />

    <input
      v-bind="inputProps"
      v-model="text"
      @focus="focusIn"
      @blur="focusOut"
      @change="$emit('change', $event)"
      ref="textBox"
      class="form-control"
      :class="{
        'is-invalid':
          (validator && validator.$model && validator.$invalid) || (validator && validator.$error),
        'is-valid': validator && validator.$model && !validator.$invalid,
        'has-error': validator && validator.$model && validator && validator.$error,
        'has-search-value': hasSearchValue && !highlightInvalid,
        'highlight-invalid': highlightInvalid,
        'text-uppercase': forceUpperCase
      }"
    />
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        :class="{ 'd-block': validator.$invalid }"
        class="validation-errors"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error mb-2" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";

export default {
  name: "TextInput",
  inheritAttrs: false,
  props: {
    noLabel: {
      type: Boolean
    },
    accessKey: {
      type: String
    },
    name: {
      required: false
    },
    label: {
      type: String
    },
    value: {
      required: false
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    validator: {
      type: Object,
      default: null
    },
    maxLength: {
      required: false
    },
    highlightSearchValue: {
      default: () => {
        return false;
      }
    },
    onFocus: {
      default: () => {
        return () => {
          return;
        };
      }
    },
    highlightInvalid: {
      default: false
    },
    forceUpperCase: {
      default: false
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  methods: {
    onChange({ value }) {
      this.text = value;
    },

    focus() {
      if (!this.isFocused) {
        this.textBox.focus();
      }
    },
    focusOut(event) {
      this.isFocused = false;
      this.$emit("blur", event);
    },
    focusIn() {
      this.isFocused = true;
      this.onFocus();
    },
    displayName(key = "", name) {
      if (key && this.label && !this.noLabel) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name || ""}</b>`;
    },
    selectAll() {
      this.focus();
      this.$nextTick(() => {
        this.$refs.textBox.setSelectionRange(0, this.text.toString().length);
      });
    }
  },
  computed: {
    inputProps() {
      return {
        ...this.$attrs,
        name: this.name,
        maxlength: this.maxLength
      };
    },
    labelId() {
      return (this.$attrs.id || this.name) + "label";
    },
    inputAttributes() {
      return { ...this.$attrs, "aria-labelledby": this.labelId, name: this.name };
    },
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    },
    textBox() {
      return this.$refs.textBox;
    },
    text: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    hasSearchValue() {
      if (!this.highlightSearchValue) {
        return false;
      }
      if (this.value !== null && this.value !== undefined && this.value !== "") {
        if (Array.isArray(this.value)) {
          return this.value.length > 0;
        }
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  font-size: 0.75rem;
}
.hide_arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
