<template>
  <div>
    <label for=""
      ><b
        >Diagn<span :class="shortkey ? 'underline' : ''">o</span>sis ICD<span
          v-if="checkModified && currentSpecimen.isICDModified"
          class="text-danger"
        >
          (modified)</span
        ></b
      ></label
    >
    <DxDropDownBox
      @initialized="initDropdown"
      v-bind="$attrs"
      v-model="gridBoxValue"
      :data-source="icdCodes"
      :search-enabled="false"
      searchModeOption="contains"
      value-expr="id"
      :display-value-formatter="displayValue"
      :show-clear-button="false"
      field-template="fieldTemplate"
      @opened="handleOpen"
      @closed="handleClose"
      :dropDownOptions="dropdownOptions"
    >
      <template v-slot:content>
        <DxDataGrid
          style="height: 100%"
          :data-source="icdCodes"
          :columns="gridColumns"
          :filter-value="filterValue"
          :hover-state-enabled="true"
          :selected-row-keys="gridBoxValue"
          @initialized="initialize"
          @selection-changed="selectItems"
          @cell-prepared="setFocus"
          :remote-operations="remoteOperations"
          :paging="paging"
          :row-alternation-enabled="true"
          :show-borders="true"
          :focused-column-index="2"
          :focused-row-index="2"
          :scrolling="scrolling"
          :cacheEnabled="false"
        >
          <DxSelection mode="multiple" :allowSelectAll="false" showCheckBoxesMode="always" />
          <DxFilterRow :visible="true" />
          <DxScrolling
            mode="standard"
            column-rendering-mode="virtual"
            row-rendering-mode="virtual"
            show-scrollbar="always"
          />
        </DxDataGrid>
      </template>
      <template v-slot:fieldTemplate>
        <div
          style="min-height: 35px"
          class="dx-texteditor-input-container d-flex flex-wrap dx-tag-container dx-native-click"
        >
          <div class="dx-tag" v-for="item in value" :key="item.id">
            <div class="dx-tag-content">
              <span>
                {{ item.displayCode || item.displayName }}
              </span>
              <div @click="removeIcd(item.id)" class="dx-tag-remove-button"></div>
            </div>
          </div>
          <DxTextBox v-show="status" />
        </div>
      </template>
    </DxDropDownBox>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error mb-2" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DxDropDownBox from "devextreme-vue/drop-down-box";
import DxTextBox from "devextreme-vue/text-box";
import { DxDataGrid, DxSelection, DxFilterRow, DxScrolling } from "devextreme-vue/data-grid";
import { DropdownApi } from "@/services/index.js";
import DataSource from "devextreme/data/data_source";
import { validatorMsgMapBase } from "@/modules/helpers";
import { mapState } from "vuex";
export default {
  name: "IcdSelector",
  inheritAttrs: false,
  components: {
    DxDropDownBox,
    DxDataGrid,
    DxSelection,
    DxFilterRow,
    DxScrolling,
    DxTextBox
  },
  props: {
    validator: {
      type: Object,
      default() {
        return {};
      }
    },
    noPrimary: {
      type: Boolean,
      default: false
    },
    setPrimary: {
      type: Function
    },
    shortkey: {
      default: false
    },
    value: {
      default: () => []
    },
    checkModified: {
      default: false
    }
  },
  data: () => {
    return {
      status: false,
      gridColumns: [
        {
          dataField: "displayCode",
          dataType: "string",
          width: "5rem"
        },
        {
          dataField: "description",
          dataType: "string"
        },
        { dataField: "isBillable", dataType: "boolean", visible: false }
      ],
      remoteOperations: {
        paging: true,
        sorting: true,
        filtering: true
      },
      paging: {
        enabled: true,
        pageSize: 10
      },
      selection: {
        showCheckBoxesMode: "always"
      },
      dropdownOptions: { width: "750px" },
      query: "",
      grid: {},
      icdCodes: new DataSource({
        store: DropdownApi.searchIcdCodes
      }),
      scrolling: { showScrollbar: "always", useNative: true }
    };
  },
  methods: {
    setFocus(event) {
      const { cellElement, columnIndex, rowType } = event;
      if (rowType === "filter" && columnIndex === 1) {
        cellElement.setAttribute("id", "filter");
        const input = cellElement.querySelector("input");
        if (input) {
          input.focus();
        }
      }
    },
    handleFocus() {
      setTimeout(() => {
        const cellElement = document.querySelector("#filter");
        if (cellElement) {
          const input = cellElement.querySelector("input");
          input.focus();
        }
      }, 0);
    },
    displayValue(data) {
      return data
        .map(e => {
          return `${e.displayCode}`;
        })
        .join("; ");
    },
    initialize(event) {
      this.grid = event.component;
    },
    initDropdown(event) {
      this.dropdownBox = event.component;
    },
    selectTags(data) {
      if (data.value?.length < data.previousValue?.length) {
        this.$emit(
          "input",
          this.value.filter(code => {
            return data.value.includes(code.id);
          })
        );
      }
    },
    handleOpen() {
      this.status = true;
      this.handleFocus();
    },
    handleClose() {
      this.status = false;
    },
    selectItems(data) {
      const rowData = (data?.selectedRowsData || [])
        .map(e => e.id)
        .sort()
        .join(", ");
      const specimenData = (this.currentSpecimen?.icdCodes || [])
        .map(e => e.id)
        .sort()
        .join(", ");
      this.$emit("input", data.selectedRowsData);
      if (this.checkModified && rowData !== specimenData) {
        this.currentSpecimen.isICDModified = true;
      }
    },
    removeIcd(id) {
      this.$emit("remove", id);
      if (this.grid.selectRows) {
        this.grid.selectRows(this.gridBoxValue.filter(e => e !== id));
      }
    },
    toggleDropdown() {
      if (!this.status) {
        this.dropdownBox.open();
      } else {
        this.dropdownBox.close();
      }
    }
  },
  computed: {
    ...mapState({ currentSpecimen: state => state.accessionStore.currentSpecimen }),
    gridBoxValue: {
      get() {
        return this.value.map(e => e.id);
      }
    },
    filterValue() {
      if (this.$route?.params?.caseId) {
        return ["isBillable", "=", true];
      }
      return [];
    },
    validatorMsgMap() {
      return validatorMsgMapBase;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
.underline {
  text-decoration: underline;
}
</style>
