<template>
  <window-portal
    :open="isOpen"
    name="ImagePreviewer"
    class="main"
    @loaded="windowRef = $event"
    @close="handleClose"
  >
    <div class="image-container">
      <template v-for="(data, idx) of urls">
        <div v-if="data.type.includes('pdf')" class="pdf-item" :key="data.id" :id="'img' + idx">
          <object :data="data.imageSrc"></object>
        </div>
        <div v-else class="image-item" :key="data.id" :id="'img' + idx">
          <img ref="image" :src="data.imageSrc" alt="" />
        </div>
      </template>
    </div>
  </window-portal>
</template>

<script>
import { mapState } from "vuex";
import WindowPortal from "./WindowPortal.vue";
export default {
  components: {
    WindowPortal
  },
  data() {
    return {
      windowRef: null,
      reportPdf: "",
      reportHTML: "",
      dynamicRoot: "ROOTDIV"
    };
  },
  computed: {
    ...mapState({
      applicationSettings: state => state.applicationSettings,
      isOpen: state => state.sessionDetails.imageFrame.isOpen,
      urls: state => state.sessionDetails.imageFrame.urls
    })
  },
  methods: {
    handleClose() {
      this.$store.commit("sessionDetails/setImageFrameStatus", false);
      this.$emit("close");
    },
    handleNewWindow() {
      this.$store.commit("sessionDetails/setImageFrameStatus", true);
    },
    changeRoot() {
      if (this.isOpen) {
        return (this.dynamicRoot = "WindowPortal");
      }
      return (this.dynamicRoot = "ROOTDIV");
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.viewer {
  margin: auto;
}
.image-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.image-item {
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  margin: 0.5rem auto;
  height: auto;
  width: auto;
  max-height: 97%;
  img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
}
.pdf-item {
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  margin: 0.5rem auto;
  width: 100%;
  height: 100%;
  object {
    width: 100%;
  }
}
</style>
