<template>
  <div class="">
    <div class="d-flex justify-content-between align-items-end mt-3 p-0">
      <label for=""><b>Provider</b></label>
      <AddBtn type="button" class="align-self-end" @click="isModalOpen = true" />
    </div>
    <DxTagBox
      class="mt-2"
      :value="value"
      :dataSource="providerSource"
      placeholder="Select providers..."
      :show-selection-controls="true"
      valueExpr="id"
      :displayExpr="providerDisplayName"
      :onInitialized="initInput"
      :maxDisplayedTags="10"
      :showDropDownButton="true"
      :search-enabled="true"
      :onValueChanged="handleValueChange"
      :searchExpr="['displayName', 'firstName', 'lastName']"
    >
      <template v-slot:tag="{ data }">
        <div
          :class="`dx-tag-content${isExpired(data) ? ' expired' : ''}`"
          v-tooltip="value.length > 10 ? 'Click to view or remove selected providers' : ''"
        >
          <span @click.stop="handleClickMulti">{{ providerDisplayName(data) }}</span>
          <icon-button
            v-show="data.isNew"
            class="edit-icon"
            icon="pen-alt"
            @click.stop="handleEdit(data.id)"
          ></icon-button>
          <div v-if="!isExpired(data)" class="dx-tag-remove-button"></div>
        </div>
      </template>
    </DxTagBox>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">{{ validatorMsgMap[key] }}</span>
      </div>
    </div>
    <modal :status="isModalOpen" @close="handleClose">
      <form
        @submit.prevent="handleSubmit"
        class="container"
        v-shortkey="saveShortkey"
        @shortkey="handleSubmit"
      >
        <h3>Providers</h3>
        <div class="d-flex">
          <TextInput
            v-focus
            class="col"
            v-model="provider.firstName"
            label="First Name"
            name="firstName"
            :validator="$v.provider.firstName"
            maxLength="51"
            :validatorMsgMap="validatorMsgMap"
          />
          <TextInput
            class="col"
            v-model="provider.middleName"
            label="Middle Name"
            name="middleName"
            maxLength="51"
            :validator="$v.provider.middleName"
            :validatorMsgMap="validatorMsgMap"
          />
          <TextInput
            class="col"
            v-model="provider.lastName"
            label="Last Name"
            name="lastName"
            maxLength="51"
            :validator="$v.provider.lastName"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
        <div class="d-flex">
          <TextInput
            class="col"
            v-model="provider.emailAddress"
            label="Email Address"
            name="emailAddress"
            :validator="$v.provider.emailAddress"
            :validatorMsgMap="validatorMsgMap"
          />
          <PhoneInput
            class="col"
            v-model="provider.phoneNumber"
            label="Phone Number"
            name="phoneNumber"
          />
          <TextInput
            class="col hide_ard-flexs"
            v-model="provider.npi"
            type="number"
            label="NPI"
            name="npi"
            maxLength="11"
            :validator="$v.provider.npi"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
        <div class="d-flex my-1">
          <SelectInput
            class="col"
            v-model="provider.secondaryOnly"
            :items="boolOptions"
            label="Secondary Only"
            name="secondaryOnly"
            id="secondaryOnly"
          />

          <TextInput
            class="col"
            v-model="provider.degrees"
            label="Degrees"
            maxLength="51"
            name="degrees"
            :validator="$v.provider.degrees"
            :validatorMsgMap="validatorMsgMap"
          />
          <PhoneInput
            class="col"
            v-model="provider.altPhoneNumber"
            label="Alternate Phone Number"
            name="phoneNumber"
          />
        </div>
        <div class="d-flex my-2 align-items-start">
          <select-input
            :multiple="false"
            class="col"
            name="billingType"
            label="Default Billing Type"
            v-model="selectedBillingType"
            :items="billingTypes"
            :customLabel="option => option.displayName"
            :validator="$v.provider.billingType"
          >
          </select-input>
          <text-input class="col" label="Legacy Provider ID" v-model="provider.legacyId" />
          <div class="col" />
        </div>
        <div class="d-flex pb-4 mb-3 border-bottom">
          <TextAreaInput
            v-model="provider.comments"
            label="Comments"
            name="comments"
            maxLength="4001"
            class="col"
            rows="5"
            cols="20"
            :resize="false"
            :validator="$v.provider.comments"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
        <div class="border p-4 mb-4">
          <CustomFields
            class="col"
            v-model="provider.providerCustomFields"
            @input="$emit('input', $event)"
            customFieldType="provider"
          />
        </div>

        <div class="d-flex justify-content-end mx-2">
          <button @click="handleClose" type="button" class="mx-2 btn btn-danger">Cancel</button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
    </modal>
    <modal :status="isSelectPopupOpen" @close="closeSelectPopup">
      <h2>Selected Providers</h2>
      <checkbox
        v-for="provider of selectedProviders"
        v-bind:key="provider.id"
        :id="provider.lastName + provider.firstName + 'Checkbox'"
        :label="provider.lastName + ', ' + provider.firstName"
        class="ml-2"
        :value="value.includes(provider.id)"
        @input="handleClickCheckbox(provider)"
        :disabled="provider.hasCases"
      />
      <div class="d-flex justify-content-end mx-2">
        <button @click="closeSelectPopup" type="button" class="mx-2 btn btn-primary">Done</button>
      </div>
    </modal>
  </div>
</template>

<script>
import PhoneInput from "@/components/common/PhoneInput.vue";
import CustomFields from "@/components/forms/CodeMaintenance/CustomFieldsTable";
import TextAreaInput from "@/components/TextAreaInput";
import { altKey, validatorMsgMapBase } from "@/modules/helpers";
import { maxLength, required, email } from "vuelidate/lib/validators";
import shorcutKeys from "@/mixins/shortcutKeys";
import { mapState } from "vuex";
import ReportsService from "../../../../services/Reports";
import { DxTagBox } from "devextreme-vue";
import SelectInput from "../../../common/SelectInput.vue";
import { ProvidersApi } from "@/services/index";
import Modal from "@/components/common/Modal.vue";
import IconButton from "@/components/common/IconButton.vue";
import { cloneDeep } from "lodash";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import DataSource from "devextreme/data/data_source";
import { sortBy } from "lodash";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "Embedded-Provider",
  components: {
    TextAreaInput,
    CustomFields,
    PhoneInput,
    SelectInput,
    DxTagBox,
    Modal,
    IconButton,
    AddBtn,
    TextInput,
    Checkbox
  },
  props: {
    value: {
      required: true
    },
    validator: {
      type: Object
    },
    newProviderNpis: {
      default() {
        return [];
      }
    },
    locationContacts: {
      default() {
        return [];
      }
    }
  },
  created() {
    ProvidersApi.providersForLocation.load().then(providers => {
      this.providers = providers || [];
    });
    window.addEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  mixins: [shorcutKeys({ keyCode: 65, modifier: "alt" }, "addCustomField")],
  data() {
    return {
      isModalOpen: false,
      providers: [],
      boolOptions: [
        { id: true, displayName: "Yes" },
        { id: false, displayName: "No" }
      ],
      selectedPathReport: null,
      billingTypes: [
        {
          id: 0,
          displayName: "Patient/Insurance"
        },
        {
          id: 1,
          displayName: "Doctor/Client"
        }
      ],
      tagBox: {},
      defaultProvider: {
        firstName: "",
        lastName: "",
        middleName: "",
        degrees: "",
        npi: "",
        secondaryOnly: false,
        comments: "",
        providerCustomFields: [],
        billingType: {},
        phoneNumber: "",
        altPhoneNumber: "",
        emailAddress: "",
        pathReportTemplateId: null,
        legacyId: null
      },
      provider: {
        firstName: "",
        lastName: "",
        middleName: "",
        degrees: "",
        npi: "",
        secondaryOnly: false,
        comments: "",
        providerCustomFields: [],
        billingType: {},
        phoneNumber: "",
        altPhoneNumber: "",
        emailAddress: "",
        pathReportTemplateId: null,
        legacyId: null
      },
      saveShortkey: altKey("s"),
      isSelectPopupOpen: false,
      selectedProviders: []
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    validatorMsgMap() {
      return { ...validatorMsgMapBase, unique: "This NPI is already registered." };
    },
    reportsSearch() {
      return ReportsService.searchStore;
    },
    providerSource() {
      return new DataSource({
        store: this.providers,
        paginate: true,
        searchOperation: "startswith",
        sort: "displayName"
      });
    },
    selectedBillingType: {
      get() {
        return this.provider.billingType?.id;
      },
      set(value) {
        this.provider.billingType = this.billingTypes.find(e => e.id === value);
        return value;
      }
    }
  },
  validations() {
    const provider = {
      firstName: {
        required,
        maxLength: maxLength(50)
      },
      lastName: {
        required,
        maxLength: maxLength(50)
      },

      middleName: {
        maxLength: maxLength(50)
      },
      billingType: {
        required
      },
      degrees: {
        maxLength: maxLength(50)
      },
      emailAddress: {
        email
      },
      npi: {
        required,
        maxLength: maxLength(10),
        unique: async value => {
          if (value) {
            if (this.newProviderNpis.includes(value)) {
              return false;
            }
            const isUnique = await ProvidersApi.searchProviders.totalCount({
              filter: ["npi", value]
            });
            return !isUnique;
          }
          return true;
        }
      },

      comments: {
        maxLength: maxLength(4000)
      },
      customFields: {
        $each: {
          field: {
            required,
            maxLength: maxLength(50)
          },
          value: {
            required,
            maxLength: maxLength(4000)
          }
        }
      }
    };
    return { provider };
  },
  watch: {
    selectedPathReport(nv) {
      if (nv) {
        if (nv?.id !== undefined && nv.id !== this.provider.pathReportTemplateId) {
          this.provider.pathReportTemplateId = nv.id;
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    initInput({ component }) {
      this.tagBox = component;
    },
    handleEdit(id) {
      this.tagBox.close();
      const target = this.providers.find(e => e.id === id);
      if (target) {
        this.provider = target;
        this.isModalOpen = true;
      }
    },
    handleClose() {
      if (JSON.stringify(this.defaultProvider) !== JSON.stringify(this.provider)) {
        window.confirm("You may have unsaved data, are you sure?").then(res => {
          if (res) {
            this.isModalOpen = false;
            this.provider = cloneDeep(this.defaultProvider);
          }
        });
      } else {
        this.isModalOpen = false;
        this.provider = cloneDeep(this.defaultProvider);
      }
    },
    handleValueChange(data) {
      this.$emit("input", data.value);
    },
    providerDisplayName(data) {
      if (typeof data === "string") {
        return data;
      }
      if (data) {
        return data.displayName ? data.displayName : `${data.lastName}, ${data.firstName}`;
      }
      return "";
    },
    async handleSubmit() {
      //IMPLEMENT CALLS FOR INSERT OR UPDATE
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }
      if (!this.provider.id) {
        this.provider.id = Date.now();
      }
      const newProvider = {
        ...this.provider,
        isNew: true
      };
      this.providers.push(newProvider);
      this.$emit("submit", newProvider);
      this.isModalOpen = false;
      this.provider = cloneDeep(this.defaultProvider);
    },
    isExpired(data) {
      if (this.locationContacts.length) {
        const { isExpired } = this.locationContacts.find(e => e.providerId === data.id) || false;
        return isExpired;
      }
      return false;
    },
    handleScroll(event) {
      if (event.target.className !== "dx-item-content dx-list-item-content") {
        this.tagBox.close();
      }
    },
    handleClickMulti() {
      if (this.value?.length > 5) {
        this.isSelectPopupOpen = true;
        this.getProviderData();
      } else {
        this.tagBox.open();
      }
    },
    getProviderData() {
      for (const providerId of this.value) {
        const providerData = this.providers.find(e => e.id === providerId);
        this.selectedProviders.push({
          id: providerData.id,
          lastName: providerData.lastName,
          firstName: providerData.firstName,
          hasCases: providerData.hasCases
        });
      }
      this.selectedProviders = sortBy(this.selectedProviders, "lastName");
    },
    closeSelectPopup() {
      this.isSelectPopupOpen = false;
      this.selectedProviders = [];
    },
    handleClickCheckbox(data) {
      if (this.value.includes(data.id)) {
        this.$emit(
          "input",
          this.value.filter(e => e !== data.id)
        );
      } else {
        this.$emit("input", [...this.value, data.id]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
::v-deep.edit-icon {
  margin: 0 5px;
  padding: 0;
  font-size: 12px;
  color: #333;
}
/* Firefox */
.expired {
  text-decoration: line-through;
}
</style>
