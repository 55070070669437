<template>
  <div
    class="wrapper rounded"
    :class="{ hidePane: isFirefox } + (fullWidth ? ' w-100 px-3' : '')"
    data-private="redact"
  >
    <div id="toolbar" class="d-flex justify-content-between">
      <div class="d-flex" v-if="supportsPDF">
        <icon-button @click="handleZoom(-10)" icon="search-minus" class="btn text-white" />
        <span class="mx-2 zoom level"> {{ pdfZoom }}% </span>
        <icon-button icon="search-plus" class="btn text-white" @click="handleZoom(10)" />
      </div>
      <div class="d-flex mx-auto" v-if="!supportsPDF">
        <icon-button class="btn text-white mr-1" icon="arrow-left" @click="changePage(-1)" />
        <icon-button class="btn text-white" icon="arrow-right" @click="changePage(1)" />
      </div>
      <div class="d-flex align-item-center flex-grow jusity-content-between">
        <slot name="customButtons"></slot>
        <icon-button class="btn text-white" icon="download" @click="handlePdfDownload" />
        <icon-button
          class="btn text-white"
          v-show="supportsPDF"
          icon="print"
          @click="handlePrint"
        />
        <icon-button icon="sync" class="btn text-white" @click="$emit('refresh')" />
        <icon-button class="btn text-white" icon="times" @click="$emit('close')" />
      </div>
    </div>
    <div class="info-overlay-wrapper">
      <InfoOverlay
        v-if="isInfoOverlayOpen && caseDetails.specimens.length"
        class="info-overlay"
        :caseDetails="caseDetails"
        @close="$emit('closeInfo')"
      />
    </div>
    <object
      v-if="supportsPDF"
      ref="pdfFrame"
      :data="embeddedUrl"
      :key="zoom"
      type="application/pdf"
      class="pdf rounded"
    >
      <p>Oops! Your browser doesn't support PDFs! Download it instead!</p>
      <p>
        <a :href="embeddedUrl" class="btn btn-primary" download="Ip Pro Pdf">Download</a>
      </p>
    </object>
    <div v-else class="text-center">
      <img :src="embeddedUrl" class="mt-1" />
    </div>
  </div>
</template>

<script>
import printJs from "print-js";
import { format } from "date-fns";
import { mapGetters, mapState } from "vuex";
import IconButton from "./IconButton.vue";
import InfoOverlay from "../InfoOverlay.vue";

export default {
  components: { IconButton, InfoOverlay },
  name: "PDFViewer",
  props: {
    url: {
      required: true
    },
    fileName: {
      default() {
        return "PDF";
      }
    },
    externalDownload: {
      default() {
        return false;
      }
    },
    fullWidth: {
      default() {
        return false;
      }
    },
    isInfoOverlayOpen: {
      default() {
        return false;
      }
    },
    caseDetails: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      rotate: 0
    };
  },
  watch: {
    caseId(nv, ov) {
      if (nv !== ov) {
        this.page = 1;
      }
    }
  },
  computed: {
    ...mapState({
      pdfZoom: state => state.applicationSettings.pdfZoom,
      isMobileView: state => state.isMobileView,
      caseId: state => state.report.caseId
    }),
    ...mapGetters("report", ["supportsPDF"]),
    embeddedUrl() {
      return `${this.url}#toolbar=0&navpanes=0&zoom=${this.pdfZoom}`;
    },
    isFirefox() {
      return navigator.userAgent.indexOf("Firefox") > 0;
    },
    options() {
      return {
        pdfOpenParams: {
          view: "Fit",
          scrollbars: "0",
          toolbar: "0",
          statusbar: "0",
          navpanes: "0",
          pagemode: "none"
        }
      };
    },
    zoom: {
      get() {
        return this.pdfZoom;
      },
      set(value) {
        this.$store.commit("applicationSettings/setPdfZoom", value);
        return value;
      }
    }
  },
  methods: {
    changePage(number) {
      this.$emit("changePage", number);
    },
    handleLoadIframe(event) {
      console.log("Iframe load", event);
    },
    handleZoom(amount) {
      const currentZoom = this.pdfZoom;
      if (this.pdfZoom + amount > 0 || this.pdfZoom + amount < 110) {
        this.$store.commit("applicationSettings/setPdfZoom", currentZoom + amount);
      }
    },
    handlePrint() {
      ///Make call for logging.
      printJs(this.url, "pdf");
      this.$emit("print");
    },
    handlePdfDownload() {
      if (this.supportsPDF && !this.externalDownload) {
        var a = document.createElement("a");
        a.href = this.url;
        a.download = `${this.fileName}_${format(new Date(), "MM/dd/yyyy")}_PATH_REPORT.pdf`;
        a.click();
      }
      this.$emit("download");
    }
  }
};
</script>

<style lang="scss" scoped>
#toolbar {
  align-items: center;
  background-color: rgb(50, 54, 57);
  color: white;
  display: flex;
  padding: 5px 10px;
  width: 100%;
}
.wrapper {
  height: fit-content;
  width: 80%;
  overflow: auto;
  color: black;
  &.hidePane {
    overflow: initial;
    /* position: relative; */
    #toolbar {
      position: relative;
      z-index: 52;
    }
    .pdf {
      position: relative;
      top: -30px;
      z-index: 51;
    }
  }
  @media screen and (max-width: 700px) {
    width: fit-content;
  }
  margin: auto;
  img {
    max-width: 100%;
    height: auto;
  }
  .pdf {
    width: 100%;
    height: 75vh;
    overflow: visible;
    margin-top: -2px;
  }
  &.windowed {
    color: $white;
    width: 100%;
    height: 100vh;
    background-color: rgba(82, 86, 89, 1);
    overflow: auto;
    button {
      color: white;
      font-size: 1.5rem;
    }
    .pdf {
      height: 93.5%;
    }
  }
  /* max-height: 1500px; */
}
::v-deep .pdf {
  #outerContainer #mainContainer div.toolbar {
    display: none !important; /* hide PDF viewer toolbar */
  }
  #outerContainer #mainContainer #viewerContainer {
    top: 0 !important; /* move doc up into empty bar space */
  }
}
.info-overlay-wrapper {
  position: relative;
}
.info-overlay {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  z-index: 10000000000;
}
</style>
