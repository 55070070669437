<template>
  <div class="slides_popup">
    <div class="d-flex">
      <SelectInput class="col" label="Print" :items="targetOptions" v-model="target" />
      <div class="col">
        <printer-select-with-status
          v-show="target === 1"
          label="Cassette Printer"
          name="cassettePrinter"
          :dataSource="labPrinters"
          v-model="cassettePrinter"
        />
        <printer-select-with-status
          v-show="target === 2"
          label="Paper Slide Printer"
          name="slidePrinter"
          :dataSource="labPrinters"
          v-model="slidePrinter"
        />
        <printer-select-with-status
          v-show="target === 3"
          label="Glass Slide Printer"
          name="glassSlidePrinter"
          :dataSource="labPrinters"
          v-model="glassSlidePrinter"
        />
        <printer-select-with-status
          v-show="target === 4"
          name="worksheetPrinter"
          label="Worksheet Printer"
          :dataSource="labPrinters"
          v-model="worksheetPrinter"
        />
        <div class="row" v-if="target === 5">
          <text-input
            class="col"
            label="File Drop Printer"
            :value="fileDropPrinterName"
            :disabled="true"
          />
          <select-input
            class="col"
            label="Tray Name"
            v-model="fileDropTray"
            :items="fileDropTrayOptions"
            valueExpr="displayName"
          />
        </div>
      </div>
    </div>
    <div class="d-flex text-uppercase flex-column my-2">
      <div v-if="caseQuery" class="d-flex align-items-center justify">
        <h6><span class="text-capitalize">Case Number</span> {{ caseQuery }}</h6>
        <button
          v-if="caseId"
          v-tooltip="'View case orders.'"
          @click="handleProcedureModal"
          class="mx-2 btn btn-outline-primary"
        >
          Orders
        </button>
      </div>
      <div v-if="specimenQuery">
        <span class="text-capitalize">Specimen:</span> {{ specimenQuery }}
      </div>
      <div v-if="blockQuery"><span class="text-capitalize"> Block:</span> {{ blockQuery }}</div>
    </div>
    <div v-show="!isCassettes" class="">
      <dx-grid-with-search
        :columnChooser="true"
        v-stream:content-ready="gridReady$"
        v-stream:selection-changed="selection$"
        :columns="slideColumns"
        :dataSource="slideDataStore"
        v-stream:initialized="initialize$"
        :selection="selection"
        title="Cassettes & Slides"
        :focusedRowIndex="1"
        :toolbar="toolbar"
        gridName="slideGrid"
        @initialized="initSlideGrid"
        :searchPanel="searchPanel"
      >
        <template v-slot:extraActions>
          <div class="d-flex">
            <div
              class="d-flex justify-content-end align-items-center"
              v-if="!caseQuery && labSettings.CassetteSlidePrintingShowHoldCodeFilter"
            >
              <tag-input
                class="mr-1"
                :dataSource="holdCodesDataSource"
                placeholder="Search by Hold Codes"
                v-model="holdQuery"
                width="300px"
              />
              <DxSwitch
                class="mr-3"
                v-tooltip="
                  holdSearchType
                    ? 'Searching cases with selected codes'
                    : 'Searching cases without selected holds'
                "
                switched-on-text="Any"
                switched-off-text="None"
                v-model="holdSearchType"
                width="3rem"
              />
            </div>
            <loader size="small" class="align-self-center" v-show="isLoading"></loader>
            <button v-if="target !== 5" v-stream:click="print$" class="btn btn-outline-primary">
              Print
            </button>
          </div>
        </template>
      </dx-grid-with-search>
    </div>
    <div v-show="isCassettes" class="">
      <dx-grid-with-search
        :columnChooser="true"
        v-stream:content-ready="gridReady$"
        v-stream:selection-changed="selection$"
        :columns="cassetteColumns"
        :dataSource="cassetteDataStore"
        v-stream:initialized="initialize$"
        :selection="selection"
        title="Cassettes & Slides"
        :focusedRowIndex="1"
        :toolbar="toolbar"
        gridName="cassetteGrid"
        @initialized="initCassetteGrid"
        :searchPanel="searchPanel"
      >
        <template v-slot:extraActions>
          <div class="d-flex">
            <div
              class="d-flex justify-content-end align-items-center"
              v-if="!caseQuery && labSettings.CassetteSlidePrintingShowHoldCodeFilter"
            >
              <tag-input
                class="mr-1"
                :dataSource="holdCodesDataSource"
                placeholder="Search by Hold Codes"
                v-model="holdQuery"
                width="300px"
              />
              <DxSwitch
                class="mr-3"
                v-tooltip="
                  holdSearchType
                    ? 'Searching cases with selected codes'
                    : 'Searching cases without selected holds'
                "
                switched-on-text="Any"
                switched-off-text="None"
                v-model="holdSearchType"
                width="3rem"
              />
            </div>
            <loader size="small" class="align-self-center" v-show="isLoading"></loader>
            <button
              v-if="target === 5"
              @click="printCassettesFileDrop(data)"
              class="btn btn-outline-primary"
            >
              Print
            </button>
            <button v-else v-stream:click="print$" class="btn btn-outline-primary">Print</button>
          </div>
        </template>
      </dx-grid-with-search>
    </div>
    <modal :status="procedureModal" @close="handleCloseProceduresPopup">
      <procedure-popup :caseId="caseId" />
    </modal>
  </div>
</template>

<script>
import { from, fromEvent, fromEventPattern, throwError, merge } from "rxjs";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import { mapState, mapGetters } from "vuex";
import {
  calculateAccessionNumbering,
  filterCellUTC,
  formatDatetimeCell,
  toLetters,
  fromLetters
} from "../modules/helpers";
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  last,
  map,
  startWith,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom
} from "rxjs/operators";
import Modal from "./common/Modal.vue";
import ProcedurePopup from "./ProcedurePopup.vue";
import {
  PrintersApi,
  ReportsApi,
  PrefixApi,
  CasesApi,
  DropdownApi,
  CassettesApi,
  MacrosApi
} from "../services";
import { sortBy } from "lodash";
import Loader from "./common/Loader.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import { MacroTypeEnum, SpecimenNumbersEnum } from "@/modules/enums";
import DataSource from "devextreme/data/data_source";
import { handleErrors } from "@/modules/handleErrors";
import PrinterSelectWithStatus from "@/components/PrinterSelectWithStatus.vue";
import { printFileDrop } from "@/modules/printFileDrop";
import TextInput from "./common/TextInput.vue";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import ScannerDetection from "@/modules/scanner";
import moment from "moment";
import TagInput from "./common/TagInput.vue";
import DxSwitch from "devextreme-vue/switch";
import { uniq } from "lodash";
import { AccessionNumberingTypeEnum } from "@/modules/enums";

const SLIDE_TABLE_SOURCE = {
  Cassettes: 1,
  Slides: 2,
  GlassSlides: 3,
  Worksheet: 4,
  CassettesFileDrop: 5
};

export default {
  name: "SlidesPopup",
  components: {
    DxGridWithSearch,
    Modal,
    ProcedurePopup,
    Loader,
    SelectInput,
    PrinterSelectWithStatus,
    TextInput,
    TagInput,
    DxSwitch
  },
  props: {
    caseNumberFilter: {
      required: false
    }
  },

  created() {
    PrintersApi.getLabPrinters().then(res => {
      this.labPrinters = new DataSource({
        store: res || [],
        sort: ["displayName"]
      });
    });
    DropdownApi.getPriorities().then(res => {
      this.priorities = res.map(e => e.displayName) || [];
    });
    this.target = this.defaultPrintMode || SLIDE_TABLE_SOURCE.Slides;
    this.slidePrinter = this.defaultSlidePrinter;
    this.cassettePrinter = this.defaultCassettePrinter;
    this.worksheetPrinter = this.defaultWorksheetPrinter;
    this.glassSlidePrinter = this.defaultGlassSlidePrinter;
  },
  data() {
    return {
      targetTypes: SLIDE_TABLE_SOURCE,
      searchPanel: {
        width: 300,
        visible: !this.caseNumberFilter
      },
      toolbar: {
        items: [{ location: "after", template: "extraActions" }]
      },
      target: null,
      labPrinters: [],
      focusedRow: 1,
      grouping: {
        autoExpandAll: false
      },
      slidePrinter: null,
      cassettePrinter: null,
      worksheetPrinter: null,
      glassSlidePrinter: null,
      caseId: "",
      targetSlides: [],
      caseQuery: null,
      specimenQuery: null,
      blockQuery: null,
      procedureModal: false,
      isLoading: false,
      slideGrid: {},
      cassetteGrid: {},
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true,
        grouping: true
      },
      selection: {
        allowSelectAll: true,
        mode: "multiple",
        showCheckBoxesMode: "always"
      },
      fileDropTray: null,
      priorities: [],
      lastBarcodeScan: 0,
      isAwaitingBarcodeRefresh: false,
      holdQuery: [],
      holdSearchType: true,
      lastBarcodePrint: 0
    };
  },
  watch: {
    procedureModal(nv) {
      if (!nv && this.targetDataStore.reload) {
        this.targetDataStore.reload();
      }
    }
  },
  domStreams: ["gridReady$", "initialize$", "selection$", "print$", "search$", "printFromEnter$"],
  subscriptions() {
    const keydown$ = fromEvent(document, "keydown");
    const loaded$ = this.$watchAsObservable("targetDataStore", { immediate: true }).pipe(
      switchMap(({ newValue }) => {
        return fromEventPattern(
          handler => newValue.on("loaded", handler),
          handler => newValue.off("loaded", handler)
        );
      })
    );
    const storeData$ = loaded$.pipe(
      map(event => {
        const [data] = event;
        this.targetSlides = data;
        const now = new Date().getTime();
        if (now - this.lastBarcodeScan > 3000) {
          const caseQuery = new RegExp(this.caseQuery, "i");
          if (caseQuery) {
            for (const accession of data) {
              if (caseQuery.test(accession.caseNumber)) {
                this.caseId = accession.caseId;
                break;
              }
            }
          }
        }
        return data;
      })
    );

    const printFromBarcode$ = this.$watchAsObservable("isAwaitingBarcodeRefresh").pipe(
      filter(({ newValue }) => newValue),
      distinctUntilChanged(),
      switchMap(() => this.gridReady$),
      filter(() => {
        const now = new Date().getTime();
        if (now - this.lastBarcodeScan > 10000 || now - this.lastBarcodePrint < 3000) {
          return false;
        }
        const rows = this.grid.getVisibleRows();
        this.isAwaitingBarcodeRefresh = false;
        if (rows.length) {
          this.lastBarcodePrint = now;
          return true;
        } else {
          window.notify("No slides pending.", "warning");
        }
        return false;
      })
    );

    const grid$ = this.initialize$.pipe(
      filter(data => data?.event?.msg?.component),
      map(data => data.event.msg.component)
    );
    const searchInputFocus$ = this.gridReady$.pipe(
      take(1),
      map(() => {
        const searchPanel = document.querySelector("input[aria-label='Search in the data grid']");
        if (searchPanel) {
          searchPanel.focus();
        }
        return searchPanel;
      })
    );

    const clearSelection$ = this.$watchAsObservable("target", { immediate: true }).pipe(
      withLatestFrom(grid$),
      tap(data => {
        const [{ newValue, oldValue }, grid] = data;
        if (this.hasSelection && grid && (oldValue == 1 || newValue === 1)) {
          grid.clearSelection();
        } else {
          this.$nextTick(() => {
            grid.selectRows(grid.getSelectedRowKeys());
          });
        }
      })
    );

    const printFromEnter$ = keydown$.pipe(
      filter(e => e.keyCode === 13 && document.activeElement === this.$refs.searchPanel)
    );
    const triggerPrint$ = merge(printFromEnter$, this.print$, printFromBarcode$).pipe(
      throttleTime(500),
      filter(() => {
        let message = "";
        let status = false;
        switch (this.target) {
          case 1:
            message = "No cassette printer selected.";
            status = Boolean(this.cassettePrinter);
            break;
          case 2:
            message = "No slide printer selected.";
            status = Boolean(this.slidePrinter);
            break;
          case 3:
            message = "No glass slide printer selected.";
            status = Boolean(this.glassSlidePrinter);
            break;
          case 4:
            message = "No worksheet printer selected.";
            status = Boolean(this.worksheetPrinter);
            break;
          case 5:
            message = "This lab does not have a FileDrop URL set up.";
            status = Boolean(this.labSettings.PrintToFileDropUrl);
            break;
          default:
            status = false;
            break;
        }
        if (!status) {
          window.notify(message, "warning");
          this.isAwaitingBarcodeRefresh = false;
        }
        return status;
      }),
      map(() => {
        this.isLoading = true;
        let data = [];
        if (this.selectedSlide$.length) {
          data = this.selectedSlide$;
        } else {
          data = this.grid.getVisibleRows().map(e => e.data);
        }
        const { SlidePrintWarning, SlidePrintMax, CassettePrintMax, CassettePrintWarning } =
          this.labSettings;
        let payload;
        let message;
        let targetApi;
        let numberOfItems = 0;
        switch (this.target) {
          case SLIDE_TABLE_SOURCE.Cassettes: //cassetes
            payload = this.labSettings.CassettePrintingUsesBatching
              ? [
                  {
                    labId: this.currentLab,
                    printerId: this.cassettePrinter,
                    cassetteIds: data.map(e => e.cassetteId)
                  }
                ]
              : Object.values(
                  data.reduce((acc, current) => {
                    if (!acc[current.cassetteId]) {
                      acc[current.cassetteId] = {
                        specimenId: current.specimenId,
                        slideId: current.slideId,
                        caseId: current.caseId,
                        numberOfCopies: 1,
                        labId: this.currentLab,
                        printerId: this.cassettePrinter,
                        cassetteId: current.cassetteId
                      };
                    }
                    return acc;
                  }, {})
                );

            numberOfItems = this.labSettings.CassettePrintingUsesBatching
              ? Array.isArray(payload)
                ? payload[0].cassetteIds.length
                : payload.cassetteIds.length
              : payload.length;
            message = `You're about to print ${numberOfItems} cassettes(s). Do you want to continue?`;
            if (numberOfItems > parseInt(CassettePrintMax)) {
              payload = [1];
              targetApi = () => {
                throw new Error(
                  `Your print exceeds the maximum allowed cassettes print (${CassettePrintMax}). <br> Please see contact lab admin to adjust this setting.`
                );
              };
            } else if (numberOfItems >= parseInt(CassettePrintWarning)) {
              if (!this.labSettings.CassettePrintingUsesBatching) {
                payload = [payload];
              }
              targetApi = params =>
                from(window.confirm(message)).pipe(
                  switchMap(res =>
                    res
                      ? this.labSettings.CassettePrintingUsesBatching
                        ? ReportsApi.printBlockLabelsBatch(params)
                        : from(params).pipe(concatMap(data => ReportsApi.printBlockLabels(data)))
                      : throwError("Print Cancelled")
                  )
                );
            } else {
              targetApi = this.labSettings.CassettePrintingUsesBatching
                ? ReportsApi.printBlockLabelsBatch
                : ReportsApi.printBlockLabels;
            }
            break;

          case SLIDE_TABLE_SOURCE.Slides: // Paper Slides
            payload = {
              printerId: this.slidePrinter,
              numberOfCopies: 1,
              slideIds: sortBy(data || [], [
                "caseId",
                "specimenOrder",
                "blockNum",
                "slideLabelNumber"
              ]).map(slide => slide.slideId)
            };

            message = `You're about to print ${payload.slideIds.length} slide(s). Do you want to continue?`;

            if (payload.slideIds.length > parseInt(SlidePrintMax)) {
              payload = [1];
              targetApi = () => {
                throw new Error(
                  `Your print exceeds the maximum allowed slides print (${SlidePrintMax}).`
                );
              };
            } else if (payload.slideIds.length >= parseInt(SlidePrintWarning)) {
              payload = [payload];
              targetApi = params =>
                from(window.confirm(message)).pipe(
                  switchMap(res =>
                    res
                      ? ReportsApi.printSlideLabels(this.currentLab, params)
                      : throwError("Print Cancelled")
                  )
                );
            } else {
              payload = [payload];
              targetApi = params => ReportsApi.printSlideLabels(this.currentLab, params);
            }
            //print slides
            break;

          case SLIDE_TABLE_SOURCE.GlassSlides: // Glass Slides
            payload = this.labSettings.SlidePrintingUsesBatching
              ? [
                  {
                    printerId: this.glassSlidePrinter,
                    labId: this.currentLab,
                    slideIds: sortBy(data || [], [
                      "caseId",
                      "specimenOrder",
                      "blockNum",
                      "slideLabelNumber"
                    ]).map(e => e.slideId)
                  }
                ]
              : sortBy(data || [], ["caseId", "specimenOrder", "blockNum", "slideLabelNumber"]).map(
                  slide => {
                    return {
                      slideId: slide.slideId,
                      caseId: slide.caseId,
                      specimenId: slide.specimenId,
                      printerId: this.glassSlidePrinter,
                      labId: this.currentLab,
                      numberOfCopies: 1,
                      cassetteId: slide.cassetteId
                    };
                  }
                );
            numberOfItems = this.labSettings.SlidePrintingUsesBatching
              ? payload[0].slideIds.length
              : payload.length;
            message = `You're about to print ${numberOfItems} slide(s). Do you want to continue?`;
            if (numberOfItems > parseInt(SlidePrintMax)) {
              payload = [1];
              targetApi = () => {
                throw new Error(
                  `Your print exceeds the maximum allowed slides print (${SlidePrintMax}).`
                );
              };
            } else if (numberOfItems >= parseInt(SlidePrintWarning)) {
              payload = [payload];
              targetApi = params =>
                from(window.confirm(message)).pipe(
                  switchMap(res =>
                    res
                      ? this.labSettings.SlidePrintingUsesBatching
                        ? ReportsApi.printGlassSlidesBatch(data)
                        : from(params).pipe(
                            concatMap(data => ReportsApi.printGlassSlideLabels(data))
                          )
                      : throwError("Print Cancelled")
                  )
                );
            } else {
              targetApi = this.labSettings.SlidePrintingUsesBatching
                ? ReportsApi.printGlassSlidesBatch
                : ReportsApi.printGlassSlideLabels;
            }
            //print Glass slides
            break;

          case SLIDE_TABLE_SOURCE.Worksheet: {
            //Worksheet
            payload = [
              {
                labId: this.currentLab,
                printerId: this.worksheetPrinter,
                slideId: data.map(e => e.slideId),
                numberOfCopies: 1
              }
            ];
            if (payload.slideId > 300) {
              targetApi = () =>
                window.alert(`Your print exceeds the maximum allowed slides print (300).`);
            } else {
              targetApi = ReportsApi.printSlidesWorksheet;
            }
            break;
          }
          default:
            break;
        }
        return { payload, targetApi };
      }),
      switchMap(item => {
        const { payload, targetApi } = item;
        return from(payload).pipe(
          concatMap(params => targetApi(params)),
          last(),
          tap(() => {
            this.isLoading = false;
          })
        );
      }),
      tap(() => {
        window.notify("Print successful.");
        if (this.isAwaitingBarcodeRefresh) {
          this.isAwaitingBarcodeRefresh = false;
        }
        const { searchPanel } = this.$refs;
        if (searchPanel) {
          this.caseQuery = null;
          this.specimenQuery = null;
          this.blockQuery = null;
          searchPanel.value = "";
          this.grid.clearFilter("search");
        }
        if (this.grid && this.target !== 4) {
          this.grid.clearSelection();
          this.grid.refresh(true);
        }
      }),
      catchError((e, source) => {
        handleErrors(e);
        this.isLoading = false;
        return source;
      })
    );
    const selectedSlide$ = this.selection$.pipe(
      map(({ event }) => {
        const { selectedRowsData } = event.msg;
        return selectedRowsData;
      }),
      startWith([])
    );
    const searchQuery$ = this.gridReady$.pipe(
      map(({ event }) => event.msg),
      take(1),
      switchMap(({ component, element }) => {
        const searchElement = element.querySelector('input[type="text"]');
        return fromEvent(searchElement, "input").pipe(
          distinctUntilChanged(),
          tap(event => {
            const searchQuery = event.target.value;
            const matcher =
              /(?<caseNumber>([a-z]*[0-9]{2}[0-9]+))\|(?<specimenOrder>[\w]+)\|(?<blockNum>[\w]+)/i;

            if (matcher.test(searchQuery)) {
              const match = searchQuery.match(matcher);
              const { caseNumber, specimenOrder, blockNum } = match.groups;
              if (specimenOrder) {
                this.specimenQuery = specimenOrder;
              }
              if (blockNum) {
                this.blockQuery = blockNum;
              }
              return component.searchByText(caseNumber);
            } else {
              this.caseQuery = null;
              this.specimenQuery = null;
              this.blockQuery = null;
            }
            return component.searchByText(searchQuery);
          })
        );
      })
    );
    return {
      searchQuery$,
      selectedSlide$,
      searchInputFocus$,
      panelFocus$: keydown$.pipe(
        filter(e => e.altKey && e.keyCode === 75),
        tap(() => {
          const element = this.$refs.searchPanel;
          if (element) {
            element.focus();
          }
        })
      ),
      storeData$,
      triggerPrint$,

      clearSelection$
    };
  },
  methods: {
    handleProcedureModal() {
      this.$store.dispatch("accessionStore/getCaseDetails", this.caseId);

      this.procedureModal = true;
    },
    handleCloseProceduresPopup() {
      this.procedureModal = false;
      if (this.grid.refresh) {
        this.grid.refresh(true);
      }
    },
    displayBlockNum(slide) {
      let contactSpecimenNumbering = slide.specimenNumbering;
      if (
        contactSpecimenNumbering === SpecimenNumbersEnum.UseLabSettings ||
        !contactSpecimenNumbering
      ) {
        contactSpecimenNumbering = this.labSettings.SpecimenNumberingTypes;
      }
      if (contactSpecimenNumbering === SpecimenNumbersEnum.Numbers) {
        return toLetters(slide.blockNum);
      }
      return slide.blockNum;
    },
    initSlideGrid({ component }) {
      this.slideGrid = component;
    },
    initCassetteGrid({ component }) {
      this.cassetteGrid = component;
    },
    print() {
      this.triggerPrint$.next();
    },
    async printCassettesFileDrop(cassettes) {
      let data = [];
      if (cassettes) {
        data = cassettes;
      } else {
        if (this.selectedSlide$.length) {
          data = this.selectedSlide$;
        } else {
          data = this.storeData$;
        }
      }
      const { CassettePrintMax, CassettePrintWarning } = this.labSettings;
      let trayName = this.fileDropTray;
      const defaultFileDropTray = this.FileDropPrinting?.defaultTray;
      this.isLoading = true;
      const labBinMaps = await PrintersApi.getLabBinMaps();
      let payload = [];
      const caseIds = uniq(data.map(e => e.caseId));
      const cases = await CasesApi.getFileDropPrintInfo(caseIds);
      const protocols = await MacrosApi.getMacroListByType(MacroTypeEnum.Protocol);
      for (const cassette of data) {
        let trayName = defaultFileDropTray;
        const caseDetails = cases.find(e => e.caseId === cassette.caseId);
        const { firstName, lastName } = caseDetails;
        if (cassette?.protocolId) {
          const { protocolId } = cassette;
          const targetProtocol = protocols.data.find(e => e.macroId === protocolId);
          let specimenBinMap = {};
          if (targetProtocol?.binMapId) {
            specimenBinMap = labBinMaps.find(e => (e.id = targetProtocol.binMapId));
            const binMapObject = specimenBinMap.printers.find(
              e => e.printerId === parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID)
            );
            if (binMapObject.bin) {
              trayName = binMapObject.bin;
            }
          }
        }
        payload.push({
          ...cassette,
          cassetteTrayName: trayName,
          patientFirstName: firstName,
          patientLastName: lastName
        });
      }
      const message = `You're about to print ${payload.length} cassettes(s). Do you want to continue?`;
      if (payload.length > parseInt(CassettePrintMax)) {
        window.alert(
          `Your print exceeds the maximum allowed cassettes print (${CassettePrintMax}). <br> Please see contact lab admin to adjust this setting.`
        );
        this.isLoading = false;
        return;
      } else if (payload.length >= parseInt(CassettePrintWarning)) {
        const confirm = await window.confirm(message);
        if (!confirm) {
          window.notify("Print cancelled.", "error");
          this.isLoading = false;
          return;
        }
      }
      try {
        const printed = await printFileDrop(payload, trayName);
        if (!printed?.ok) {
          window.alert("An error occurred in printing. Please try again");
          return;
        }
        const cassettesToMark = payload.map(e => e.cassetteId);
        await CassettesApi.markCassettesAsPrinted(cassettesToMark);
        window.notify("Print successful.");
        this.grid.clearSelection();
        this.grid.refresh();
      } catch (error) {
        window.alert("An error occurred in printing. Please try again");
      } finally {
        this.isLoading = false;
      }
    },
    async handleScanBarcode(barcode) {
      const now = new Date().getTime();
      if (now - this.lastBarcodeScan < 3000) {
        return;
      } else {
        this.lastBarcodeScan = now;
      }
      this.devlog("Scanned barcode in Print Slides Popup", barcode);
      const { caseNumber, specimenOrder, blockNum } = scanCaseBarcode(barcode);
      if (!caseNumber) {
        return;
      }
      const searchPanel = document.querySelector("input[aria-label='Search in the data grid']");
      searchPanel.value = "";
      this.caseQuery = caseNumber;
      const numberSectionsRegex = new RegExp(
        "(?<prefix>[a-z]{1,6})(?<year>[0-9]{4})-(?<number>[0-9]{1,7})",
        "i"
      );
      const {
        groups: { prefix, year, number }
      } = numberSectionsRegex.exec(caseNumber);
      const numberWithoutZeros = number.replace(/^0*/, "");
      this.grid.clearFilter();
      this.grid.clearFilter("search");
      this.grid.clearFilter("dataSource");
      this.grid.columnOption("prefix", "filterValue", prefix);
      this.grid.columnOption("year", "filterValue", year);
      this.grid.columnOption("numberSequence", "filterValue", numberWithoutZeros);
      const isSlides = [SLIDE_TABLE_SOURCE.Slides, SLIDE_TABLE_SOURCE.GlassSlides].includes(
        this.target
      );
      this.grid.columnOption(isSlides ? "slidePrinted" : "cassettePrinted", "filterValue", false);
      this.grid.columnOption(isSlides ? "cassettePrinted" : "slidePrinted", "filterValue", null);
      if (specimenOrder) {
        this.grid.columnOption("specimenOrder", "filterValue", specimenOrder);
        if (blockNum) {
          this.grid.columnOption(
            "blockNum",
            "filterValue",
            /[a-z]/i.test(blockNum) ? fromLetters(blockNum) : parseInt(blockNum)
          );
          if (isSlides) {
            this.isAwaitingBarcodeRefresh = true;
          }
        }
      }
    },
    handleClearGridFilters() {
      this.grid.clearFilter("row");
      this.grid.clearFilter("header");
      this.grid.clearFilter("filterValue");
      this.grid.columnOption("caseStatus", "filterValues", this.statusFilter);
      this.grid.clearFilter("search");
      this.grid.clearFilter("dataSource");
    }
  },
  mounted() {
    this.scanner = new ScannerDetection({
      onComplete: this.handleScanBarcode,
      stopPropogation: true,
      minLength: 4,
      timeBeforeScanTest: 250
    });
  },
  beforeDestroy() {
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  computed: {
    ...mapState({
      labSettings: state => state.labSettings,
      currentLab: state => state.currentLab,
      applicationSettings: state => state.applicationSettings,
      defaultSlidePrinter: state => state.applicationSettings.defaultSlidePrinter,
      defaultPrintMode: state => state.applicationSettings.defaultPrintMode,
      defaultWorksheetPrinter: state => state.applicationSettings.defaultWorksheetPrinter,
      defaultCassettePrinter: state => state.applicationSettings.defaultCassettePrinter,
      defaultGlassSlidePrinter: state => state.applicationSettings.defaultGlassSlidePrinter,
      FileDropPrinting: state => JSON.parse(state.labSettings.FileDropPrintingConfiguration)
    }),
    ...mapGetters("accessionStore", ["specimenNumbering"]),
    hasSelection() {
      return this.selectedSlide$.length;
    },
    targetDataStore() {
      if (this.isCassettes) {
        return this.cassetteDataStore;
      }
      return this.slideDataStore;
    },

    numberingType() {
      return this.labSettings?.AccessionNumberingType ?? 0;
    },
    targetBlocks() {
      return [];
    },
    cassetteColumns() {
      return [
        {
          dataField: "slidePrinted",
          allowSearch: false,
          dataType: "boolean",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id",
            allowSearch: false
          },
          width: "100px"
        },
        {
          width: "100px",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          filterValue: false,
          dataField: "cassettePrinted",
          dataType: "boolean",
          allowSearch: false
        },
        {
          dataField: "priority",
          dataType: "string",
          lookup: {
            dataSource: this.priorities
          }
        },
        {
          dataField: "protocol",
          dataType: "string",
          allowFiltering: true
        },
        {
          dataField: "caseNumber",
          calculateFilterExpression: (value, operator) => {
            value = /([A-Z]{1,6})?(((19)|(20))?[0-9]{2})?-?0{0,6}([1-9][0-9]{0,6})?/i.exec(
              value
            )[0];
            const hasMatcher = value.split("|");
            if (hasMatcher.length > 1) {
              value = hasMatcher[0];
            }
            if (value !== calculateAccessionNumbering(value, this.numberingType)) {
              value = calculateAccessionNumbering(value, this.numberingType);
              this.caseQuery = value;
            } else if (
              value.length > 7 &&
              value === calculateAccessionNumbering(value, this.numberingType)
            ) {
              this.caseQuery = value;
            } else {
              this.caseQuery = "";
            }
            if (operator) {
              return ["caseNumber", operator, value];
            }
            return ["caseNumber", "contains", value];
          },
          dataType: "string",
          visible: false
        },
        {
          dataField: "prefix",
          dataType: "string",
          lookup: {
            displayExpr: "code",
            valueExpr: "code",
            dataSource: PrefixApi.searchStore
          },
          sortIndex: this.isPrefixNumbering ? 0 : null,
          sortOrder: this.isPrefixNumbering ? "asc" : null
        },
        {
          dataField: "year",
          dataType: "number",
          sortIndex: 1,
          sortOrder: "desc"
        },
        {
          dataField: "numberSequence",
          caption: "Case Number",
          dataType: "number",
          sortIndex: 2,
          sortOrder: "asc"
        },
        {
          dataField: "specimenOrder",
          caption: "Specimen",
          width: "70px",
          dataType: "string",
          sortIndex: 3,
          sortOrder: "asc",
          filterValue: this.specimenQuery
        },
        {
          dataField: "blockNum",
          width: "70px",
          dataType: "string",
          caption: "Block",
          sortIndex: 4,
          sortOrder: "asc",
          filterValue: this.blockQuery,
          allowFiltering: true,
          allowSorting: true,
          allowSearching: true,
          calculateCellValue: slide => this.displayBlockNum(slide)
        },
        {
          dataField: "orderDescription",
          dataType: "string",
          allowFiltering: true
        },
        {
          dataField: "userAdded",
          dataType: "string",
          caption: "Added By"
        },
        {
          dataField: "userAddedDateTime",
          caption: "Added Time",
          dataType: "datetime",
          calculateFilterExpression: filterCellUTC("userAddedDateTime"),
          calculateCellValue(data) {
            return formatDatetimeCell(data.userAddedDateTime);
          }
        },
        {
          dataField: "isHistologyAutoComplete",
          caption: "Auto Histo",
          dataType: "boolean",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          allowSearch: false
        },
        {
          dataField: "isPathologistAutoComplete",
          caption: "Auto Path",
          dataType: "boolean",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          allowSearch: false
        }
      ];
    },
    slideColumns() {
      return [
        {
          dataField: "slidePrinted",
          allowSearch: false,
          dataType: "boolean",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          width: "100px",
          filterValue: false
        },
        {
          width: "100px",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          dataField: "cassettePrinted",
          dataType: "boolean",
          allowSearch: false
        },
        {
          dataField: "priority",
          dataType: "string",
          lookup: {
            dataSource: this.priorities
          }
        },
        {
          dataField: "protocol",
          dataType: "string",
          allowFiltering: true
        },
        {
          dataField: "caseNumber",
          calculateFilterExpression: (value, operator) => {
            value = /([A-Z]{1,6})?(((19)|(20))?[0-9]{2})?-?0{0,6}([1-9][0-9]{0,6})?/i.exec(
              value
            )[0];
            const hasMatcher = value.split("|");
            if (hasMatcher.length > 1) {
              value = hasMatcher[0];
            }

            if (value !== calculateAccessionNumbering(value, this.numberingType)) {
              value = calculateAccessionNumbering(value, this.numberingType);
              this.caseQuery = value;
            } else if (
              value.length > 7 &&
              value === calculateAccessionNumbering(value, this.numberingType)
            ) {
              this.caseQuery = value;
            } else {
              this.caseQuery = "";
            }
            if (operator) {
              return ["caseNumber", operator, value];
            }
            return ["caseNumber", "contains", value];
          },
          dataType: "string",
          visible: false
        },
        {
          dataField: "prefix",
          dataType: "string",
          lookup: {
            displayExpr: "code",
            valueExpr: "code",
            dataSource: PrefixApi.searchStore
          },
          sortIndex: this.isPrefixNumbering ? 0 : null,
          sortOrder: this.isPrefixNumbering ? "asc" : null
        },
        {
          dataField: "year",
          dataType: "number",
          sortIndex: 1,
          sortOrder: "desc"
        },
        {
          dataField: "numberSequence",
          caption: "Number",
          sortIndex: 2,
          sortOrder: "asc",
          dataType: "number",
          calculateCellValue(data) {
            return data.numberSequence.toString().padStart(7, "0");
          }
        },
        {
          dataField: "specimenOrder",
          width: "70px",
          caption: "Specimen",
          dataType: "string",
          sortIndex: 3,
          sortOrder: "asc",
          filterValue: this.specimenQuery
        },
        {
          dataField: "blockNum",
          dataType: "number",
          width: "70px",
          caption: "Block",
          sortIndex: 4,
          sortOrder: "asc",
          calculateCellValue: slide => this.displayBlockNum(slide),
          filterValue: this.blockQuery
        },
        {
          dataField: "slideLabelNumber",
          dataType: "number",
          caption: "Slide Number",
          sortIndex: 5,
          sortOrder: "asc"
        },
        {
          dataField: "slideLabelDescription",
          dataType: "string",
          caption: "Slide Label"
        },
        {
          dataField: "orderDescription",
          dataType: "string",
          allowFiltering: true
        },
        {
          dataField: "userAdded",
          dataType: "string",
          caption: "Added By"
        },
        {
          dataField: "userAddedDateTime",
          caption: "Added Time",
          dataType: "datetime",
          calculateFilterExpression: filterCellUTC("userAddedDateTime"),
          calculateCellValue(data) {
            return formatDatetimeCell(data.userAddedDateTime);
          }
        },
        {
          dataField: "printedOn",
          dataType: "date",
          calculateFilterExpression: filterCellUTC("printedOn"),
          calculateCellValue(data) {
            if (!data?.printedOn) {
              return "";
            }
            return formatDatetimeCell(data.printedOn);
          }
        },
        {
          dataField: "printedBy",
          dataType: "string"
        },
        {
          dataField: "isHistologyAutoComplete",
          caption: "Auto Histo",
          dataType: "boolean",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          allowSearch: false
        },
        {
          dataField: "isPathologistAutoComplete",
          caption: "Auto Path",
          dataType: "boolean",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          allowSearch: false
        }
      ];
    },
    targetOptions() {
      let options = [
        {
          displayName: "Cassettes",
          id: 1
        },
        {
          displayName: "Slides",
          id: 2
        },
        {
          displayName: "Slides (Glass)",
          id: 3
        },
        {
          displayName: "Worksheet",
          id: 4
        }
      ];
      if (this.FileDropPrinting?.printerName) {
        options.push({
          displayName: "Cassettes (File Drop)",
          id: 5
        });
      }
      return options;
    },
    fileDropPrinterName() {
      return this.FileDropPrinting?.printerName;
    },
    fileDropTrayOptions() {
      return this.FileDropPrinting?.trayOptions || [];
    },
    holdCodesDataSource() {
      const now = moment().format("yyyy-MM-DDTHH:mm:ss");
      const effectiveFilter = [
        [["effectiveOn", "<", now], "or", ["effectiveOn", "=", null]],
        "and",
        [["expiryOn", ">", now], "or", ["expiryOn", "=", null]]
      ];
      return new DataSource({
        store: DropdownApi.searchHoldCodes,
        filter: effectiveFilter,
        sort: ["displayName"]
      });
    },
    cassetteDataStore() {
      if (!this.isCassettes) {
        return [];
      }
      let query = "";
      if (this.holdQuery.length) {
        query = { hcids: this.holdQuery, any: this.holdSearchType };
      }
      return new DataSource({
        store: ReportsApi.cassetteListWithHolds(query),
        filter: this.caseNumberFilter ? ["caseNumber", "=", this.caseNumberFilter] : null
      });
    },
    slideDataStore() {
      if (this.isCassettes) {
        return [];
      }
      let query = "";
      if (this.holdQuery.length) {
        query = { hcids: this.holdQuery, any: this.holdSearchType };
      }
      return new DataSource({
        store: ReportsApi.slideListWithHolds(query),
        filter: this.caseNumberFilter ? ["caseNumber", "=", this.caseNumberFilter] : null
      });
    },
    isPrefixNumbering() {
      return this.labSettings.AccessionNumberingType === AccessionNumberingTypeEnum.Prefix;
    },
    isCassettes() {
      return [this.targetTypes.Cassettes, this.targetTypes.CassettesFileDrop].includes(this.target);
    },
    grid() {
      return this.isCassettes ? this.cassetteGrid : this.slideGrid;
    }
  }
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 300px;
  &::after {
    top: 12%;
    right: 10%;
    position: absolute;
    content: "ALT+K";
    font-weight: 500;
    border: 0.5px solid gray;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    color: gray;
  }
  &:focus-within {
    &::after {
      display: none;
    }
  }
}
.slides_popup {
  width: 90vw;
}
</style>