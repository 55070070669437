<template>
  <div>
    <div class="bg-white p-2" v-if="!isLoading">
      <div class="underlined">
        <h6>
          <u> Properties for Selected Provider/Location Combination(s) </u>
        </h6>
      </div>
      <div v-if="inconsistencies">
        <p class="inconsistent-header text-muted">Inconsistent properties are marked with</p>
      </div>
      <div class="">
        <h6>
          Currently editing {{ value.length }} {{ value.length > 1 ? "contacts" : "contact" }} -
        </h6>
        <ul>
          <li v-for="contact in value" :key="contact.contactId">
            {{ contact.displayName }}
          </li>
        </ul>
      </div>
      <form
        ref="mainForm"
        @submit.prevent="handleFormSubmit"
        id="propertiesForm"
        class="d-flex justify-content-between w-100 ml-0 p-0 my-3"
        v-shortkey="saveShortkey"
        @shortkey="handleFormSubmit"
      >
        <div class="container">
          <div class="container mb-3">
            <div class="row align-items-center">
              <h4 class="col">General Config</h4>
              <div class="row justify-content-end">
                <DatePicker
                  v-if="permissions.ContactFieldEditEffectiveOn"
                  label="Effective On"
                  class="col"
                  name="effectiveOn"
                  :class="{
                    inconsistent: inconsistentProperties.includes('effectiveOn')
                  }"
                  v-model="newRowValues.effectiveOn"
                />

                <DatePicker
                  v-if="permissions.ContactFieldEditExpiryOn"
                  label="Expires On"
                  name="expiresOn"
                  class="col"
                  :class="{
                    inconsistent: inconsistentProperties.includes('expiryOn')
                  }"
                  v-model="newRowValues.expiryOn"
                  :validator="$v.newRowValues.expiryOn"
                />
              </div>
            </div>
            <div class="row border-bottom pb-2 mb-4">
              <div class="col border-right pr-5">
                <SelectInput
                  :disabled="!permissions.ContactFieldEditReportFormat"
                  v-focus
                  displayExpr="name"
                  name="reportId"
                  :dataSource="reportsStore"
                  label="Path Report Template"
                  maxLength="1001"
                  :class="{
                    inconsistent: inconsistentProperties.includes('reportId')
                  }"
                  v-model="newRowValues.reportId"
                  :validator="$v.newRowValues.reportId"
                />
                <SelectInput
                  :disabled="!permissions.ContactFieldEditReportFormat"
                  v-focus
                  displayExpr="name"
                  v-if="cytologyModuleEnabled"
                  name="cytPathReportId"
                  :dataSource="reportsStore"
                  label="Cytology Path Report Template"
                  maxLength="1001"
                  :class="{
                    inconsistent: inconsistentProperties.includes('cytPathReportId')
                  }"
                  v-model="newRowValues.cytPathReportId"
                  :validator="$v.newRowValues.cytPathReportId"
                />

                <div class="d-flex justify-content-between">
                  <SelectInput
                    v-model="newRowValues.patientNameOrder"
                    name="patientNameOrder"
                    :items="dropDowns.patientNameOrder"
                    :class="{
                      inconsistent: inconsistentProperties.includes('patientNameOrder')
                    }"
                    label="Patient Name Order"
                  />

                  <SelectInput
                    :items="dropDowns.includePatientHistory"
                    name="includePatientHistory"
                    :class="{
                      inconsistent: inconsistentProperties.includes('includePatientHistory')
                    }"
                    label="Patient History"
                    v-model="newRowValues.includePatientHistory"
                  />
                </div>
                <div class="d-flex justify-content-between">
                  <SelectInput
                    :items="dropDowns.includePatientAddress"
                    label="Patient Address"
                    name="includePatientAddress"
                    :class="{
                      inconsistent: inconsistentProperties.includes('includePatientAddress')
                    }"
                    v-model="newRowValues.includePatientAddress"
                  />
                  <SelectInput
                    :items="dropDowns.includeICD"
                    name="includeICD"
                    :class="{
                      inconsistent: inconsistentProperties.includes('includeICD')
                    }"
                    label="Include ICD"
                    v-model="newRowValues.includeICD"
                  />
                </div>
                <div class="row">
                  <select-input
                    class="col"
                    label="Default Pathologist"
                    :items="pathologists"
                    v-model="newRowValues.defaultPathologistId"
                  />
                </div>
              </div>
              <div class="col pl-5">
                <SelectInput
                  v-model="newRowValues.specimenNumbering"
                  name="specimenNumbering"
                  :class="{
                    inconsistent: inconsistentProperties.includes('specimenNumbering')
                  }"
                  :items="dropDowns.specimenNumbering"
                  label="Specimen Numbering"
                />
                <div class="d-flex justify-content-between">
                  <SelectInput
                    :items="billingRates"
                    name="billingRates"
                    label="Billing Rate Number"
                    :class="{
                      inconsistent: inconsistentProperties.includes('billingRateNumber')
                    }"
                    v-model="newRowValues.billingRateNumber"
                  />
                  <SelectInput
                    :items="billingTypes"
                    name="defaultBillingType"
                    class="mx-2"
                    :class="{
                      inconsistent: inconsistentProperties.includes('defaultBillingType')
                    }"
                    label="Default Billing Type"
                    v-model="newRowValues.defaultBillingType"
                  />
                  <number-input
                    name="extraSlides"
                    :class="{
                      inconsistent: inconsistentProperties.includes('extraSlides')
                    }"
                    label="Extra Slides"
                    v-model="newRowValues.extraSlides"
                  />
                </div>

                <div class="d-flex justify-content-start">
                  <NumberInput
                    label="Route Number"
                    type="number"
                    name="routeNumber"
                    :class="{
                      inconsistent: inconsistentProperties.includes('routeNumber')
                    }"
                    v-model="newRowValues.routeNumber"
                    :validator="$v.newRowValues.routeNumber"
                  />
                  <SelectInput
                    class="p-0 mx-2"
                    label="Default Bin Map"
                    name="binMapId"
                    displayExpr="name"
                    :items="labBinMaps"
                    v-model="newRowValues.binMapId"
                    :class="{
                      inconsistent: inconsistentProperties.includes('binMapId')
                    }"
                  />
                  <!-- <TextInput
                    class="p-0 mx-1"
                    label="Slide Tray"
                    name="slideLabelBin"
                    v-model="newRowValues.slideLabelBin"
                    maxLength="15"
                    :class="{
                      inconsistent: inconsistentProperties.includes('slideLabelBin')
                    }"
                  /> -->
                  <!-- 
                  <TextInput
                    class="p-0"
                    label="Cassette Tray"
                    v-model="newRowValues.binMapId"
                    name="cassetteLabelBin"
                    maxLength="15"
                    :class="{
                      inconsistent: inconsistentProperties.includes('cassetteLabelBin')
                    }"
                  /> -->
                </div>
                <SelectInput
                  :items="timezones"
                  name="timezones"
                  label="Time Zone"
                  :validator="$v.newRowValues.timeZoneId"
                  v-model="newRowValues.timeZoneId"
                />
              </div>
            </div>
            <div class="pb-4 mb-4 border-bottom">
              <text-area-input
                v-model="newRowValues.comments"
                label="Comments"
                name="comments"
                maxLength="40001"
                rows="5"
                cols="20"
                :resize="false"
                :validator="$v.newRowValues.comments"
                :class="{
                  inconsistent: inconsistentProperties.includes('comments')
                }"
              />
            </div>
            <div class="row pb-4 mb-4 border-bottom">
              <div class="w-100">
                <div class="d-flex justify-content-between">
                  <DatePicker
                    v-if="permissions.ContactFieldEditPrintStartOn"
                    class="col"
                    name="printStart"
                    label="Print Start"
                    :class="{
                      inconsistent: inconsistentProperties.includes('printStart')
                    }"
                    v-model="newRowValues.printStart"
                  />
                  <DatePicker
                    class="col"
                    v-if="permissions.ContactFieldEditPrintEndOn"
                    label="Print End"
                    name="printEnd"
                    :validator="$v.newRowValues.printEnd"
                    v-model="newRowValues.printEnd"
                    :class="{
                      inconsistent: inconsistentProperties.includes('printEnd')
                    }"
                    :validatorMsgMap="{
                      afterPrintStart: 'Must be after print start date.'
                    }"
                  />
                </div>
                <div class="d-flex justify-content-between">
                  <div class="col" v-if="value.length < 2">
                    <label><b>Printers</b></label>
                    <DxTagBox
                      placeholder="Add a printer."
                      :dataSource="printerSearch"
                      name="printerIds"
                      value-expr="id"
                      :display-expr="printerDisplayName"
                      v-model="newRowValues.printerIds"
                    />
                  </div>
                  <div class="row col d-flex wrap">
                    <NumberInput
                      v-if="cytologyModuleEnabled"
                      v-model="newRowValues.extraCytCopies"
                      :class="{
                        inconsistent: inconsistentProperties.includes('extraCytCopies')
                      }"
                      :validator="$v.newRowValues.extraCytCopies"
                      type="number"
                      name="extraCytCopies"
                      class="col-6"
                      label="Extra Cyt. Copies"
                    />
                    <NumberInput
                      v-model="newRowValues.copies"
                      :class="{
                        inconsistent: inconsistentProperties.includes('copies')
                      }"
                      class="col-6"
                      :validator="$v.newRowValues.copies"
                      type="number"
                      name="copies"
                      label="Extra Copies"
                    />
                    <SelectInput
                      :class="{
                        inconsistent: inconsistentProperties.includes('printPrelim'),
                        col: true
                      }"
                      :items="dropDowns.printPrelim"
                      class="col-6"
                      label="Print Prelim"
                      name="printPrelim"
                      v-model="newRowValues.printPrelim"
                    />
                    <SelectInput
                      :items="dropDowns.printFinal"
                      label="Print Final"
                      class="col-6"
                      v-model="newRowValues.printFinal"
                      name="printFinal"
                      :class="{
                        inconsistent: inconsistentProperties.includes('printFinal')
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="value.length < 2" class="mt-4 ml-2 row">
            <Checkbox
              id="isAutoReport"
              name="isAutoReport"
              label="Auto Report"
              :disabled="distributionSelected"
              v-model="newRowValues.isAutoReport"
            />
          </div>
          <div v-if="value.length < 2" class="mt-1 row">
            <HL7Table class="w-100" v-model="newRowValues.hL7Configs" />
          </div>
          <div v-if="value.length < 2" class="row">
            <EmailTable class="w-100" v-model="newRowValues.emails" />
          </div>
          <div v-if="value.length < 2" class="row">
            <PDFTable class="w-100" v-model="newRowValues.pdfFileDrops" />
          </div>
          <div v-if="value.length < 2" class="row">
            <PhonesTable class="w-100" v-model="newRowValues.phoneNumbers" />
          </div>
          <div v-if="value.length < 2" class="row">
            <FaxTable
              :timezone="selectedTimezone"
              class="w-100"
              v-model="newRowValues.faxMachines"
            />
          </div>
          <div v-if="value.length < 2" class="row border-bottom pb-4 mb-2">
            <CustomFields
              class="w-100"
              v-model="newRowValues.contactCustomFields"
              customFieldType="contact"
            />
          </div>
          <div class="row justify-content-end mt-1">
            <button type="button" @click="$emit('cancel')" class="btn btn-danger">Cancel</button>
            <button type="submit" class="btn btn-primary mx-1">Submit</button>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="d-flex flex-column justify-content-center align-items-center">
      <loader class="mx-auto"></loader>
      <div class="text-muted">Loading...</div>
    </div>
  </div>
</template>

<script>
import HL7Table from "@/components/forms/CodeMaintenance/HL7Table";
import EmailTable from "@/components/forms/CodeMaintenance/EmailTable";
import FaxTable from "@/components/forms/CodeMaintenance/FaxTable";
import PDFTable from "@/components/forms/CodeMaintenance/PDFDropTable";
import PhonesTable from "@/components/forms/CodeMaintenance/PhonesTable";
import ProviderAPI from "@/services/providers";
import DxTagBox from "devextreme-vue/tag-box";
import { numeric, maxLength, maxValue, required } from "vuelidate/lib/validators";
import { isAfter, isValid, parseISO } from "date-fns";
import { startCase } from "lodash";
import DropdownApi from "@/services/dropdown.js";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import ReportsService from "../../../../services/Reports";
import NumberInput from "@/components/common/NumberInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import Loader from "@/components/common/Loader.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import { altKey, oneYearAgo } from "@/modules/helpers";
import { AuditLogApi, PrintersApi } from "@/services";
import Checkbox from "@/components/common/Checkbox.vue";
import CustomFields from "@/components/forms/CodeMaintenance/CustomFieldsTable";
import TextAreaInput from "@/components/TextAreaInput.vue";
import { createLogItem } from "@/modules/helpers";
import { createLogComment } from "@/modules/helpers";
import { cloneDeep } from "lodash";
import { handleErrors } from "@/modules/handleErrors";

export default {
  name: "Contacts-Form",
  props: ["value"],
  components: {
    HL7Table,
    EmailTable,
    PDFTable,
    FaxTable,
    PhonesTable,
    DxTagBox,
    Loader,
    NumberInput,
    SelectInput,
    DatePicker,
    Checkbox,
    CustomFields,
    TextAreaInput
  },
  created() {
    DropdownApi.getBillingRates().then(res => {
      this.billingRates = res || [];
    });
    PrintersApi.getLabBinMaps().then(res => {
      this.labBinMaps = res || [];
    });
    this.$store.dispatch("dropdowns/getBillingTypes");
    if (this.value.length) {
      ProviderAPI.getContactsById(this.value.map(e => e.contactId)).then(res => {
        this.dataSet = res;
        this.checkAllValues(res);
      });
    }
    if (this.value.length < 2) {
      ProviderAPI.createPrinterSearch(this.value[0].location.id)
        .load({
          filter: ["isIgnored", false]
        })
        .then(res => {
          this.printerSearch = res || [];
          for (const printer of this.value[0]?.properties?.printers) {
            if (!this.printerSearch.find(e => e.id === printer.id)) {
              this.printerSearch.push({ id: printer.id, name: "Unknown Printer" });
              const printerIndex = this.newRowValues.printerIds.indexOf(printer.id);
              this.newRowValues.printerIds.push(
                this.newRowValues.printerIds.splice(printerIndex, 1)[0]
              );
            }
          }
        });
      if (this.value[0]?.contactCustomFields?.length) {
        this.newRowValues.contactCustomFields = this.value[0].contactCustomFields;
      }
    }
    if (!this.pathologists?.length) {
      this.$store.dispatch("dropdowns/getPathologists");
    }
    this.$store.dispatch("dropdowns/getTimeZones");
    this.isLoading = true;
  },
  data() {
    return {
      reportsStore: ReportsService.searchStore,
      isLoading: false,
      originalContact: {},
      printerSearch: [],
      billingRates: [],
      dataSet: [],
      labBinMaps: [],
      dropDownEnums: {
        includeICD: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        includePatientAddress: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        includePatientHistory: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        patientNameOrder: [
          {
            id: 1,
            displayName: "First, Last"
          },
          {
            id: 2,
            displayName: "Last, First"
          }
        ],
        printPrelim: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        specimenNumbering: [
          { id: 3, displayName: "Use Lab Settings" },
          { id: 1, displayName: "Numbers" },
          { id: 2, displayName: "Letters" }
        ],
        printFinal: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ]
      },

      inconsistentProperties: [],
      inconsistencies: false,
      newRowValues: {
        includeICD: null,
        includePatientAddress: null,
        includePatientHistory: null,
        reportId: "",
        billingRateNumber: "",
        defaultBillingType: null,
        specimenNumbering: null,
        printFinal: null,
        printPrelim: null,
        patientNameOrder: null,
        printStart: null,
        printEnd: null,
        effectiveOn: null,
        extraSlides: 0,
        expiryOn: null,
        hL7Configs: [],
        emails: [],
        binMapId: null,
        phoneNumbers: [],
        pdfFileDrops: [],
        printerIds: [],
        printers: [],
        faxMachines: [],
        routeNumber: 0,
        copies: 0,
        extraCytCopies: 0,
        cytPathReportId: null,
        timeZoneId: null,
        isAutoReport: false,
        contactCustomFields: [],
        comments: "",
        defaultPathologistId: null
      },
      defaultRow: {
        includeICD: null,
        includePatientAddress: null,
        includePatientHistory: null,
        reportId: "",
        billingRateNumber: "",
        defaultBillingType: null,
        specimenNumbering: null,
        printFinal: null,
        printPrelim: null,
        patientNameOrder: null,
        printStart: null,
        printEnd: null,
        effectiveOn: oneYearAgo(),
        extraSlides: 0,
        expiryOn: null,
        binMapId: null,
        hL7Configs: [],
        emails: [],
        phoneNumbers: [],
        printerIds: [],
        pdfFileDrops: [],
        printers: [],
        extraCytCopies: 0,
        cytPathReportId: null,
        faxMachines: [],
        routeNumber: 0,
        copies: 0,
        timeZoneId: null,
        isAutoReport: false,
        contactCustomFields: [],
        comments: ""
      },
      selectedRowsData: {
        includeICD: null,
        includePatientAddress: null,
        includePatientHistory: null,
        extraCytCopies: 0,
        cytPathReportId: null,
        reportId: null,
        billingRateNumber: null,
        defaultBillingType: null,
        specimenNumbering: null,
        printFinal: null,
        printPrelim: null,
        patientNameOrder: null,
        printStart: null,
        printEnd: null,
        printerIds: null,
        effectiveOn: null,
        extraSlides: 0,
        expiryOn: null,
        hL7Configs: null,
        emails: null,
        binMapId: null,
        phoneNumbers: null,
        pdfFileDrops: null,
        printers: null,
        faxMachines: null,
        copies: null,
        routeNumber: null,
        timeZoneId: null,
        cassetteLabelBin: null,
        slideLabelBin: null,
        isAutoReport: false,
        comments: "",
        contactCustomFields: [],
        defaultPathologistId: null
      },
      saveShortkey: altKey("s")
    };
  },
  validations: {
    newRowValues: {
      reportId: {
        maxLength: maxLength(1000)
      },
      copies: {
        numeric,
        maxValue: 999
      },
      extraCtytCopies: {
        numeric,
        minValue: 0,
        maxValue: 999
      },
      routeNumber: {
        numeric,
        maxValue: maxValue(999)
      },
      printers: {
        maxLength: maxLength(1000)
      },
      printStart: {
        required
      },
      timeZoneId: {
        required,
        notPlaceholder: value => value !== 0
      },
      printEnd: {
        afterPrintStart: function (value, vm) {
          if (value != null && value != undefined) {
            return moment(value).isSameOrAfter(vm.printStart);
          }
          return true;
        }
      },
      expiresOn: {
        afterEffective: function (value, vm) {
          if (value != null && value != undefined) {
            return isAfter(value, vm.effectiveOn);
          }
          return true;
        }
      },
      effectiveOn: {
        required
      },
      comments: {
        maxLength: maxLength(1000)
      }
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapState({
      billingTypes: state => state.dropdowns.billingTypes,
      timezones: state => state.dropdowns.timeZones,
      pathologists: state => state.dropdowns.pathologists,
      cytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled
    }),
    ...mapGetters(["permissions"]),

    selectedTimezone() {
      if (this.newRowValues.timeZoneId && this.timezones) {
        return this.timezones.find(timezone => timezone.id === this.newRowValues.timeZoneId)
          ?.displayName;
      }
      return null;
    },
    dropDowns() {
      return {
        includeICD: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        includePatientAddress: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        includePatientHistory: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        patientNameOrder: [
          {
            id: 1,
            displayName: "First, Last"
          },
          {
            id: 2,
            displayName: "Last, First"
          }
        ],
        printPrelim: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ],
        specimenNumbering: [
          { id: 3, displayName: "Use Lab Settings" },
          { id: 1, displayName: "Numbers" },
          { id: 2, displayName: "Letters" }
        ],
        printFinal: [
          {
            id: true,
            displayName: "Yes"
          },
          {
            id: false,
            displayName: "No"
          }
        ]
      };
    },
    distributionSelected() {
      return (
        this.newRowValues.hl7Configs?.length ||
        this.newRowValues.emails?.length ||
        this.newRowValues.pdfFileDrops?.length ||
        this.hasFaxPrelimOrFinal ||
        this.newRowValues.printPrelim ||
        this.newRowValues.printFinal
      );
    },
    hasFaxPrelimOrFinal() {
      return this.newRowValues.faxMachines.filter(e => e.prelim || e.final).length > 0;
    }
  },
  watch: {
    dataSet: {
      deep: true,
      handler(nv) {
        if (nv.length) {
          this.checkAllValues(nv);
        }
      }
    },
    distributionSelected: {
      immediate: false,
      handler(nv) {
        if (nv) {
          this.newRowValues.isAutoReport = false;
        }
      }
    }
  },

  methods: {
    calculateInconsistencies() {
      const props = [];
      if (this.inconsistencies) {
        for (const prop in this.selectedRowsData) {
          const set = this.selectedRowsData[prop];
          if (set.size > 1) {
            props.push(prop);
          }
        }
      }
      this.isLoading = false;
      return props;
    },
    addPrinter(newPrinter) {
      if (this.newRowValues.printers) {
        this.newRowValues.printers.push(newPrinter);
      } else {
        this.newRowValues.printers = [newPrinter];
      }
    },
    checkAllValues(values) {
      this.inconsistencies = false;
      for (const property in this.selectedRowsData) {
        this.selectedRowsData[property] = new Set();
        const propertySet = this.selectedRowsData[property];
        const field = ["contactCustomFields", "defaultPathologistId"].includes(property)
          ? values[0][property]
          : values[0]?.properties[property];
        if (!Array.isArray(field)) {
          values.forEach(value => {
            let field = value.properties[property];
            if (isValid(parseISO(field))) {
              //Checking if the field is a date value.
              //If it is we need to do an extra step in order for the set to uniquely identify it
              field = parseISO(field);
              field.setHours(0, 0, 0, 0);
              propertySet.add(field.toLocaleDateString());
            } else {
              propertySet.add(field);
            }
          });
          if (propertySet.size > 1) {
            this.inconsistencies = true;
            if (this.dropDowns[property]) {
              this.dropDowns[property] = [
                ...this.dropDownEnums[property],
                {
                  id: null,
                  displayName: "Inconsistent",
                  disabled: true
                }
              ];
              this.newRowValues[property] = null;
            } else {
              this.newRowValues[property] = this.defaultRow[property];
            }
          } else {
            this.newRowValues[property] = field;
          }
        } else {
          this.newRowValues[property] = field;
        }
      }
      return (this.inconsistentProperties = this.calculateInconsistencies());
    },
    camelCaseParse(value) {
      return startCase(value);
    },
    async handleFormSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (
        this.newRowValues.printerIds?.length &&
        !this.newRowValues.printPrelim &&
        !this.newRowValues.printFinal
      ) {
        const confirm = await window.confirm(
          "This contact has a printer selected but has not been set to print prelim or print final. Do you wish to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      if (this.value.length > 1) {
        const confirm = await window.confirm(
          "You are about to update multiple contacts, do you wish to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.isLoading = true;
      let editedContacts = [];
      await Promise.all(
        this.dataSet.map((contact, i, arr) => {
          const originalContact = cloneDeep(contact);
          let changes = false;
          for (const property in this.newRowValues) {
            const propValue = this.newRowValues[property];
            const oldProp = contact.properties[property];
            if (
              property === "contactCustomFields" &&
              contact?.contactCustomFields?.length &&
              contact.contactCustomFields !== propValue
            ) {
              changes = true;
              contact.contactCustomFields = [...contact.contactCustomFields, ...propValue];
            } else if (property === "defaultPathologistId") {
              if (oldProp !== propValue) {
                changes = true;
                contact.defaultPathologistId = propValue;
              }
            } else if (
              !Array.isArray(propValue) &&
              (!this.inconsistentProperties.includes(property) ||
                (propValue !== null && propValue !== undefined))
            ) {
              if (oldProp !== propValue) {
                changes = true;
                contact.properties[property] = propValue;
              } else {
                continue;
              }
            } else if (arr.length === 1 && Array.isArray(propValue)) {
              changes = true;
              contact.properties[property] = propValue;
            }
          }
          if (
            this.value.length === 1 &&
            contact.properties.reportId !== this.newRowValues.reportId
          ) {
            contact.properties.reportId = this.newRowValues.reportId;
            changes = true;
          }
          if (changes) {
            editedContacts.push({ original: originalContact, updated: contact });
            return ProviderAPI.updateContact(contact);
          }
        })
      )
        .then(() => {
          if (editedContacts.length) {
            for (const editedContact of editedContacts) {
              const { firstName, lastName } = editedContact.original.provider;
              const locationName = editedContact.original.location.name;
              const logItem = createLogItem({}, 5, "Edit Contact");
              logItem.comments = `Edited ${firstName} ${lastName} (${locationName})\n${createLogComment(
                editedContact.original,
                editedContact.updated
              )}`;
              AuditLogApi.insertLogMessage(logItem);
            }
          }
          this.$emit("submit");
        })
        .catch(error => {
          this.isLoading = false;
          handleErrors(error);
        });
      return () => this.$emit("submit");
    },
    printerDisplayName(data) {
      return data?.name || "Unknown Printer";
    }
  }
};
</script>

<style lang="scss" scoped>
.inconsistent-header {
  &::after {
    margin-left: 0.2rem;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23da1a32' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23da1a32' stroke='none'/%3e%3c/svg%3e") !important;
  }
}
</style>
