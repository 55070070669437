<template>
  <div class="rounded viewer d-flex flex-column justify-content-center">
    <div
      v-if="isLoading && windowed"
      :class="{ windowed }"
      class="loading-mode d-flex flex-column align-items-center"
    >
      <div class="text-danger" v-if="error">{{ error }}</div>
    </div>
    <loader
      v-if="isLoading && !windowed"
      size="small"
      class="mx-auto align-self-center"
      :style="`opacity: ${isLoading ? '100' : '0'}`"
    />
    <pdf-viewer
      v-show="!isHidden"
      v-if="!error"
      :url="reportUrl"
      :key="reportUrl"
      :class="{ windowed }"
      @close="handleClose"
      @changePage="handleChangePage"
      @print="handlePrint"
      @download="handlePdfDownload"
      @refresh="refreshReport"
      :externalDownload="true"
      :fullWidth="true"
      :isInfoOverlayOpen="isInfoOverlayOpen && reportUrl"
      :caseDetails="caseDetailsState"
      @closeInfo="toggleInfoOverlay"
    >
      <template v-slot:customButtons>
        <div>
          <icon-button
            class="text-white"
            v-tooltip="'Sign Case'"
            v-if="isReadyForSigning"
            icon="edit"
            @click="handleSignCase"
            :disabled="isSigning"
          />
          <icon-button
            class="text-white"
            v-tooltip="'View case macro, CPT, and ICD10 info.'"
            icon="info-circle"
            @click="toggleInfoOverlay"
            v-if="hasSpecimens"
          />
        </div>
      </template>
    </pdf-viewer>
    <modal :status="isLocationPopupOpen" @close="isLocationPopupOpen = false">
      <lab-location-popup
        :isLoading="isLoading"
        @cancel="isLocationPopupOpen = false"
        @sign="handleSelectLabLocation"
      />
    </modal>
    <modal :status="isCollectedPopupOpen" @close="isCollectedPopupOpen = false">
      <collected-popup @submit="handleSetCollected" @cancel="isCollectedPopupOpen = false" />
    </modal>
  </div>
</template>

<script>
import eventBus, { SIGNED_CASE_FROM_PDF, TOGGLE_HIDE_PATH_REPORT } from "../../modules/eventBus";
import { mapGetters, mapState } from "vuex";
import { delay, filter, switchMap, tap } from "rxjs/operators";
import { fromEvent } from "rxjs";
import pathReportMessages from "../../services/pathReportMessages";
import PdfViewer from "@/components/common/PDFViewer.vue";
import { CasesApi, ReportsApi } from "@/services/index";
import Loader from "../common/Loader.vue";
import IconButton from "../common/IconButton.vue";
import { format } from "date-fns";
import { CaseStatusEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
import Modal from "../common/Modal.vue";
import LabLocationPopup from "../LabLocationPopup.vue";
import CollectedPopup from "../CollectedPopup.vue";
import { checkIfProxy } from "@/modules/helpers";

export default {
  components: {
    PdfViewer,
    Loader,
    IconButton,
    Modal,
    LabLocationPopup,
    CollectedPopup
  },
  name: "PathReport",
  props: ["windowRef"],
  data() {
    return {
      imgUrl: "",
      loadedRatio: 0,
      page: 1,
      numPages: null,
      rotate: 0,
      error: "",
      isLocationPopupOpen: false,
      isCollectedPopupOpen: false,
      caseDetailsState: {},
      isSigning: false,
      isHidden: false,
      isInfoOverlayOpen: false
    };
  },
  computed: {
    ...mapState({
      reportViewer: state => state.report,
      pdfZoom: state => state.applicationSettings.pdfZoom,
      accessionMode: state => state.accessionMode,
      reportCaseId: state => state.report.caseId,
      reportUrl: state => state.report.reportUrl,
      isLoading: state => state.report.loading,
      currentCase: state => state.accessionStore.caseHeader,
      pathReportLocation: state => state.applicationSettings.pathReportLocation,
      token: state => state.token,
      isMobileView: state => state.isMobileView,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      caseStatus: state => state.report.caseStatus,
      caseDetails: state => state.accessionStore.caseDetails,
      nextCasePopup: state => state.applicationSettings.nextCasePopup,
      labSettings: state => state.labSettings,
      currentUser: state => state.currentUser,
      autoPathReportInfo: state => state.applicationSettings.autoPathReportInfo,
      defaultPathReportZoom: state => state.applicationSettings.defaultPathReportZoom
    }),
    ...mapGetters("report", ["supportsPDF"]),
    windowed() {
      return this.pathReportLocation === 1;
    },
    isReadyForSigning() {
      if (!this.caseDetailsState.pathologists?.length) {
        return false;
      }
      const primaryPatholgist = this.caseDetailsState.pathologists.find(e => e?.isPrimary);
      if (this.currentUser.id !== primaryPatholgist.guid && !checkIfProxy(primaryPatholgist.guid)) {
        return false;
      }
      return [
        CaseStatusEnum.ResultedOnHold,
        CaseStatusEnum.Resulted,
        CaseStatusEnum.ResultedAgain
      ].includes(this.caseStatus);
    },
    zoom: {
      get() {
        return this.pdfZoom;
      },
      set(value) {
        this.$store.commit("applicationSettings/setPdfZoom", value);
        return value;
      }
    },
    hasSpecimens() {
      const specimens = this.caseDetails?.caseId
        ? this.caseDetails?.specimens
        : this.caseDetailsState?.specimens;
      return specimens?.length > 0;
    }
  },
  watch: {
    "reportViewer.reportUrl": {
      immediate: true,
      async handler(nv) {
        if (nv) {
          try {
            await fetch(this.reportUrl);
          } catch {
            if (this.reportCaseId) {
              this.$store.dispatch("report/viewPathReport", { caseId: this.reportCaseId });
            }
          }
        }
      }
    },
    reportCaseId: {
      immediate: true,
      handler(nv) {
        if (nv !== this.caseDetailsState?.caseId) {
          if (this.caseDetails?.caseId && this.caseDetails?.caseId === nv) {
            this.caseDetailsState = this.caseDetails;
          } else {
            CasesApi.getCaseById(nv).then(res => {
              this.caseDetailsState = res;
            });
          }
          if (this.autoPathReportInfo) {
            this.isInfoOverlayOpen = true;
          }
        }
      }
    }
  },
  subscriptions() {
    const scrollIntoView$ = this.$watchAsObservable("isLoading", { immediate: true }).pipe(
      filter(({ newValue }) => {
        return this.accessionMode === "signout" && !newValue;
      }),
      delay(500),
      tap(() => {
        const pdfViewer = this.$refs.pdf?.$el;
        if (pdfViewer) {
          pdfViewer?.scrollIntoView({
            behavior: "smooth",
            inline: "nearest"
          });
        }
      })
    );
    const saveWindowState$ = this.$watchAsObservable("windowed", { immediate: true }).pipe(
      filter(({ newValue }) => newValue),
      switchMap(() => fromEvent(this.windowRef, "close"))
    );
    return { scrollIntoView$, saveWindowState$ };
  },

  mounted() {
    if (this.windowed) {
      this.zoom = this.defaultPathReportZoom || 80;
    }
    eventBus.$on(TOGGLE_HIDE_PATH_REPORT, value => this.toggleHidePathReport(value));
    if (this.autoPathReportInfo) {
      this.isInfoOverlayOpen = true;
    }
  },
  beforeDestroy() {
    eventBus.$off(TOGGLE_HIDE_PATH_REPORT);
  },
  methods: {
    async refreshReport() {
      await this.$store.dispatch("report/viewPathReport", { caseId: this.reportCaseId });
    },
    async handleChangePage(page) {
      if (this.reportCaseId && page + this.page > -1) {
        if (this.numPages && this.page + page > this.numPages) {
          return;
        }
        this.page += page;
        const report = await ReportsApi.getSSRSReport({
          caseId: this.reportCaseId,
          format: "IMAGE",
          outputFormat: `JPEG`,
          StartPage: this.page
        });
        const blob = new Blob([report], {
          type: "image/JPEG"
        });
        const url = URL.createObjectURL(blob);
        const doesImageExist = await this.doesImageExist(url);
        if (doesImageExist) {
          this.$store.commit("report/setReportUrl", url);
        } else {
          //Capture the last page & revert the amount of pages increased
          this.numPages = this.page;
          this.page -= page;
        }
      }
    },
    doesImageExist(url) {
      return new Promise(resolve => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    },
    async handleSignCase() {
      if (this.isSigning) {
        return;
      }
      const caseId = this.reportCaseId;
      try {
        this.isSigning = true;
        if (this.isReadyForSigning) {
          if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
            this.isLocationPopupOpen = true;
            return;
          }
          this.caseDetailsState = this.caseDetails?.caseId
            ? this.caseDetails
            : await CasesApi.getCaseById(caseId);
          if (!this.caseDetailsState.collectedOn) {
            this.isCollectedPopupOpen = true;
            return;
          }
          if (
            this.labSettings.ConfirmPrelimSignOuts &&
            this.caseStatus === CaseStatusEnum.ResultedOnHold
          ) {
            const confirm = await window.confirm(
              "One or more cases you are signing out has a hold code attached. Are you sure you want to sign these cases as prelim?"
            );
            if (!confirm) {
              return;
            }
          }
          document.body.style.cursor = "wait";
          const skipRefresh = this.$route.name === "Quick Signout";
          const SignResponse = await this.$store.dispatch("accessionStore/handleSignCase", {
            caseId,
            labLocationId: this.currentLabLocation,
            caseStatus: this.caseStatus,
            skipRefresh
          });
          if (SignResponse) {
            window.notify(
              `Successfully ${
                SignResponse?.status === CaseStatusEnum.Signed ? "signed" : "unsigned"
              } accession. `
            );
            // if (SignResponse?.batchRedirect) {
            //   batchRedirect();
            // }
          }
          eventBus.$emit(SIGNED_CASE_FROM_PDF);
        } else {
          throw "Case cannot be signed with the current status.";
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isSigning = false;
        document.body.style.cursor = "";
      }
    },
    handleClose() {
      this.$store.commit("report/toggleReportViewer", false);
      this.$emit("close");
    },
    async handlePdfDownload() {
      const report = await ReportsApi.getSSRSReport({
        caseId: this.reportCaseId,
        format: "pdf"
      });
      const blob = new Blob([report], {
        type: "application/pdf"
      });
      const url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `Path_Report_${format(new Date(), "MM/dd/yyyy")}.pdf`;
      a.click();
      pathReportMessages.addPathReportMessage({ caseIds: [this.reportCaseId], method: 6 });
    },
    handlePrint() {
      ///Make call for logging.
      pathReportMessages.addPathReportMessage({ caseIds: [this.reportCaseId], method: 7 });
    },
    handleSelectLabLocation() {
      this.handleSignCase();
      this.isLocationPopupOpen = false;
    },
    async handleSetCollected(date) {
      this.isCollectedPopupOpen = false;
      this.caseDetailsState.collectedOn = date;
      await this.$store.dispatch("accessionStore/updateCaseDetails", {
        ...this.caseDetailsState,
        patientAccountNumber: this.caseDetailsState.acctNumber
      });
      this.handleSignCase(this.caseDetailsState.caseId);
    },
    toggleHidePathReport(value) {
      this.isHidden = value;
    },
    toggleInfoOverlay() {
      this.isInfoOverlayOpen = !this.isInfoOverlayOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-mode {
  height: 100%;
  &.windowed {
    height: 100vh;
    background-color: $primary-darker;
  }
}
</style>
